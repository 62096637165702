<template>
    <div id="filter-form">
    <Panel :onInfo="'start-case-study'" v-on:start-case-study="$emit('start-case-study')" v-on:close-panel="$emit('close-panel')">
        <h1> <icon :name="'icon-sliders'" :height="'1em'"/> Filter </h1>
        <!-- <h1 class="study" @click="$emit('start-case-study')"> <icon :name="'icon-study'" :width="'2rem'" :height="'2rem'"/> Case study </h1> -->
        <form autocomplete="off">
            <section>
                <!-- {{ isPopulation, "Minimum <b> inhabitant count </b>", min, max, filterPopulation}} -->
                <div class="form-check form-switch d-flex mt-2">
                    <input class="form-check-input align-self-center" type="checkbox" role="switch" id="isPopulation" name="isPopulation" v-model="isPopulation">
                    <label class="ms-4 align-self-center" for="filterPopulation" :style="isPopulation ? null : 'color:grey;'">Minimum <b> inhabitant count </b> </label>
                    <input class="ms-auto align-self-center ps-3 w-25" type="number" min="0" max="500000" id="filterPopulation" v-model="filterPopulation" :disabled="isPopulation ? null : true">
                </div>
                <div class="form-check form-switch d-flex align-items-center mt-2">
                    <input class="form-check-input align-self-center" type="checkbox" role="switch" id="isCrop" name="isCrop" v-model="isCrop">
                    <label class="ms-4 align-self-center" for="filterCrop" :style="isCrop ? null : 'color:grey;'"><b> Main crop </b> cultivated in the area </label> <icon class="align-self-center ms-2" :name="'icon-warning'" :height="'1.8em'" :width="'1.8em'" :tooltipContent="'Fake data for demo purposes'"/>
                    <v-select class="ms-auto align-self-center w-25" inputId="filterCrop" multiple v-model="filterCrop" :options="cropOptions" :disabled="isCrop ? null : true"/>
                </div>
                <div class="form-check form-switch d-flex align-items-center mt-2">
                    <input class="form-check-input align-self-center" type="checkbox" role="switch" id="isAgent" name="isAgent" v-model="isAgent">
                    <label class="ms-4 align-self-center" for="filterDistAgent" :style="isAgent ? null : 'color:grey;'">Minimum <b> distance </b> (in km) to <b> agent </b> </label> <icon class="align-self-center ms-2" :name="'icon-warning'" :height="'1.8em'" :width="'1.8em'" :tooltipContent="'Fake data for demo purposes'"/>
                    <input class="ms-auto align-self-center ps-3 w-25" type="number" min="0" max="100000" id="filterDistAgent" v-model="filterDistAgent" :disabled="isAgent ? null : true">
                </div>
                <div class="form-check form-switch d-flex mt-2">
                    <input class="form-check-input align-self-center" type="checkbox" role="switch" id="isGrid" name="isGrid" v-model="isGrid">
                    <label class="ms-4 align-self-center" for="filterDistGrid" :style="isGrid ? null : 'color:grey;'">Minimum <b> distance </b> (in km) to <b> grid </b> </label>
                    <input class="ms-auto align-self-center ps-3 w-25" type="number" min="0" max="100000" id="filterDistGrid" v-model="filterDistGrid" :disabled="isGrid ? null : true">
                </div>
                <div class="form-check form-switch d-flex mt-2">
                    <input class="form-check-input align-self-center" type="checkbox" role="switch" id="isRoad" name="isRoad" v-model="isRoad">
                    <label class="ms-4 align-self-center" for="filterDistRoad" :style="isRoad ? null : 'color:grey;'">Minimum <b> distance </b> (in km) to <b> road </b> </label>
                    <input class="ms-auto align-self-center ps-3 w-25" type="number" min="0" max="100000" id="filterDistRoad" v-model="filterDistRoad" :disabled="isRoad ? null : true">
                </div>
                <div class="form-check form-switch d-flex mt-2">
                    <input class="form-check-input align-self-center" type="checkbox" role="switch" id="isFiber" name="isFiber" v-model="isFiber">
                    <label class="ms-4 align-self-center" for="filterDistFiber" :style="isFiber ? null : 'color:grey;'">Minimum <b> distance </b> (in km) to <b> fiber network </b> </label>
                    <input class="ms-auto align-self-center ps-3 w-25" type="number" min="0" max="100000" id="filterDistFiber" v-model="filterDistFiber" :disabled="isFiber ? null : true">
                </div>
                <div class="form-check form-switch d-flex mt-2">
                    <input class="form-check-input align-self-center" type="checkbox" role="switch" id="isPoverty" name="isPoverty" v-model="isPoverty">
                    <label class="ms-4 align-self-center" for="filterPoverty" :style="isPoverty ? null : 'color:grey;'">Population <b> below the poverty threshold </b> </label>
                    <div class="ms-auto align-self-center w-25">
                        <vue-slider class="p-0" id="filterPoverty" v-model="filterPoverty" :enable-cross="false" :contained="true"  :tooltip="'none'" :disabled="isPoverty ? null : true"
                        >
                            <span> {{ filterPoverty[0] }}% -  {{ filterPoverty[1]}}% </span> 
                        </vue-slider>
                    </div>
                </div>

                <div class="card card-body my-3 p-2 advanced-card">
                    <input type="checkbox" class="btn-check" id="btn-check" autocomplete="off" v-model="showAdvanced">
                    <label class="d-flex justify-content-start btn btn-toggle rounded border-0" :aria-expanded="showAdvanced ? true : false" for="btn-check" @click="manageAdvancedPanel">Advanced filters</label>
                    <div id="advanced">
                        <div v-for="[index, advancedFilter] of advancedFilters.entries()" :key="index">
                            <div v-if="advancedFilter.type == 'text'" class="form-check form-switch d-flex mt-2" data-bs-toggle="modal" data-bs-target="#contactModal">
                                <input class="form-check-input align-self-center" type="checkbox" role="switch" disabled>
                                <label class="ms-4 align-self-center" style="color:grey;">  {{ advancedFilter.text }} </label>
                                <input class="ms-auto align-self-center ps-3 w-25" type="number" disabled>
                            </div>
                            <div v-if="advancedFilter.type == 'checkbox'" class="d-flex mt-2" data-bs-toggle="modal" data-bs-target="#contactModal">
                                <div class="d-flex form-check form-switch">
                                    <input class="form-check-input align-self-center" type="checkbox" role="switch" disabled>
                                    <label class="ms-4 align-self-center" style="color:grey;">  {{ advancedFilter.text }} </label>
                                </div>
                                <div class="d-flex flex-row justify-content-end flex-grow-1 align-self-end">
                                    <div v-for="index in [1, 2, 3, 4, 5]" :key="index" class="form-check form-check-inline ms-3 me-0">
                                        <input class="form-check-input" type="checkbox" role="checkbox" :id="`inlineCheckbox${index}`" :value="index" disabled>
                                        <label class="form-check-label" :for="`inlineCheckbox${index}`"> {{ index }} </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="advancedFilter.type == 'empty'" class="form-check form-switch d-flex mt-2" data-bs-toggle="modal" data-bs-target="#contactModal">
                                <input class="form-check-input align-self-center" type="checkbox" role="switch" disabled>
                                <label class="ms-4 align-self-center" style="color:grey;">  {{ advancedFilter.text }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <section v-if="circleQuery" class="horizontal">
                <div class="btn-radio" v-for="radiusChoice in radiusChoices" :key="radiusChoice" @click="emitRadius">
                    <input type="radio" name="radius" autocomplete="off" :id="radiusChoice + 'radio'" :value="radiusChoice" v-model="radius">
                    <label :for="radiusChoice + 'radio'"> {{radiusChoice}} km </label>
                </div>
            </section>
            <div id="filter-buttons">
                <button v-if="!circleQuery" class="btn mx-2" id="filterQuery" type="button" v-on:click="$emit('filter-submit', $data)"> 
                    Filter <icon class="ms-2" :name="'icon-sliders'" :height="'1em'"/>
                </button>
                <!-- <button v-if="isExport && !circleQuery" class="btn mx-2" id="resetQuery" type="button" v-on:click="$emit('reset')"> 
                    Reset results <icon class="ms-2" :name="'icon-reset'" :height="'1em'"/>
                </button> -->
                <button v-if="isExport && !circleQuery" class="btn mx-2" id="exportQuery" type="button" v-on:click="$emit('export')"> 
                    Export <icon class="ms-2" :name="'icon-download'" :height="'1.2em'"/>
                </button>
            </div>
        </form>
    </Panel>
    </div>
</template>

<script>
import Panel from '@/components/Panel.vue';
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'

const countryCrops = {
    'ng': ['sesame', 'nuts', 'cashew',  'groundnut', 'cassava', 'cocoa', 'melon', 'rice', 'banana', 'plantain', 'beans', 'yams'],
};
const advancedFilters = {
    'ng' : [
        {type: "text", text: "Minimum priority level (0 to 5)"},
        {type: "text", text: "Minimimum accessibility index (0 to 10)"},
        {type: "text", text: "Minimimum energy demand index (0 to 10)"},
        {type: "text", text: "Minimimum energy gap index (0 to 10)"},
        {type: "text", text: "Maximum relative wealth index (RWI) (in %)"},
        {type: "empty", text: "B2B demand"},
        {type: "empty", text: "Presence of mini-grid"},
        // {type: "text", text: "Minimum population with 2G (in percent)"},
        // {type: "text", text: "Minimum population with 3G (in percent)"},
        // {type: "text", text: "Minimum population with 4G (in percent)"},
    ]
};

export default {
    name: 'filter-panel',
    components: {
        Panel, VueSlider, vSelect
    },
    props: {
        countryCode: String,
        circleQuery: Boolean,
        showResDiv: Boolean,
        results: String,
        exportType: String,
        legendWidth: String,
        isExport: Boolean,
        defaultRadius: Number,
    }, 
    created() {
        // non-reactive declaration
        this.radiusChoices= [2, 5, 10, 20, 40, 80];
    },
    
    data: function() {
        return { 
            isPopulation: true,
            filterPopulation: 5000,
            isPoverty: true,
            filterPoverty: [0, 80],
            isAgent: true,
            filterDistAgent: 2,
            isGrid: true,
            filterDistGrid: 2,
            isRoad: true,
            filterDistRoad: 2,
            isFiber: true,
            filterDistFiber: 2,
            isCrop: true,
            filterCrop: [],
            radius: this.defaultRadius,
            showAdvanced: false,
        }
    },
    computed: {
        cropOptions(){
            return countryCrops[this.countryCode];
        },
        advancedFilters() {
            return advancedFilters[this.countryCode];
        }
    },
    watch: {
        legendWidth() {
            const form = document.getElementById("filter-form");
            form.style.left = `calc(${this.legendWidth} + 40px)`;
        }
    },
    methods: {
        emitRadius(event) {
            this.$emit('radiusChanged', event.target.value);
        },
        manageAdvancedPanel() {
            const advanced = document.getElementById("advanced");
            if (advanced.style.height != '172px') {
                advanced.style.height = '172px';
                setTimeout(() => {  advanced.style.overflow = 'initial'; }, 500);
            }
            else {
                advanced.style.height = '0px';
                advanced.style.overflow = 'hidden';
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/filterform.scss";

#advanced {
    overflow: hidden;
    height: 0px;
    transition-duration: 0.5s;
}
.form-select {
    width: 24% !important;
}
.form-check-input {
    height: 10px;
}

#filter-form {
    position: fixed;
    bottom: 14px;
    left: 40px;
    width: calc(max(25%, 40rem));
    transition: left 1s;
}
// .study {
//     position: absolute;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     left: 2rem;
//     font-size: 0.9rem;
//     cursor: pointer;
// }
.info{
    position: absolute;
    top: 0.95em;
    right: 2.8em;
    cursor: pointer;
}

.select {
    width: 40%;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    padding: 0.3rem;
    background-color: lightgrey;
}
</style>

<template>
    <div>
            <div class="content d-flex flex-column align-items-end tools">
            <div class="circle-icon rounded-pill" v-for="button in activeButtons" v-bind:key="button.id" v-bind:id="button.id" v-on:click.stop="button.func">
                <input class="icon-content" type="radio" name="tool" autocomplete="off" :id="button.id + 'radio'">
                <img class="icon-content icone" :src="`${button.img}`" alt="icone">
                <label class="icon-content" :for="button.id + 'radio'"> {{ button.message }} </label>
                <span class="icon-content tool-name"> {{ button.tooltip }} &nbsp; &nbsp; </span>
            </div>
        </div>
        <InformationPanel  :type="enabledMode" :values="responseData" :legendWidth="legendWidth" v-on:close-panel="initTool(enabledMode)"></InformationPanel>
        <FilterForm v-show="showFilter() && !hidePanel" :countryCode="countryCode" :circleQuery="enabledMode=='circle'" :exportType="resultsType" :isExport="filterArgs == null ? false : true "
        :showResDiv="vw > threshSmallDisplay"
        :legendWidth="legendWidth"
        :defaultRadius="defaultRadius"
        v-on:filter-submit="filterRequest"
        v-on:export="exportRequest(resultIds)"
        v-on:radiusChanged="changeRadius"
        v-on:close-panel="initTool(enabledMode)"
        v-on:start-case-study="$emit('start-case-study')"
        v-on:reset="reset()"
        ref="filter" >
            <!-- <transition name="fade"> <div v-if="queryMessage != null" :class="[msgTypeClass, 'results']"> {{ queryMessage }} </div> </transition> -->
        </FilterForm>
        <PopupInfo v-show="popupData != null" ref='popup' :title="popupTitle" :values="popupData" v-on:export-cell="exportRequest(curId)"></PopupInfo>
    </div>
</template>

<script>
import InformationPanel from '@/components/InformationPanel.vue';
import FilterForm from '@/components/FilterForm.vue';
import PopupInfo from '@/components/PopupInfo.vue';
import { startSpin, stopSpin } from '@/util/spin.js';
import { allMarkerStyle, agentColor } from '@/util/markers.js';
import { fillPopup, defIconClusterStyle } from '@/util/utils.js';

import {styles} from '../style/layerStyles';
import L from 'leaflet';
import "leaflet-path-drag";
import * as d3 from 'd3-format';
import * as D3 from 'd3';
import 'leaflet.markercluster';

import {formatObject, formatDistance} from "@/util/formatting.js";
import {bboxToWKT, pointToWKT, WKTToLatLngBounds} from "@/util/wkt.js";


const formatter = d3.format(',.2s');

function simpleSettPopup(msg, exportFunc, ids, displayExport = true) {
    const base = L.DomUtil.create('div');
    const msgContainer = L.DomUtil.create('span', null, base);
    msgContainer.innerHTML = msg;
    if (displayExport) {
        const btn = L.DomUtil.create('button', "btn", base);
        btn.innerHTML = "Export settlements info";
        btn.addEventListener("click", () => exportFunc(ids));
    }
    return base;
}

const clusterIcon = L.icon({
    iconUrl:      require('@/assets/img/icons/ico-user-green.svg'),
    iconSize:     [40, 40], // size of the icon
    iconAnchor:   [20, 40], // point of the icon which will correspond to marker's location
    popupAnchor:  [0, -40] // point from which the popup should open relative to the iconAnchor
});

const distIcon = L.icon({
    iconUrl:      require('@/assets/img/icons/ico-flag-green.svg'),
    iconSize:     [40, 40],
    iconAnchor:   [20, 40],
    popupAnchor:  [0, -40]
});

const maxSettToDisplay = 30;
const threshSmallDisplay = 600;
export default {
    name: 'Tools',
    props: ['map', 'countryCode', 'linkedData', 'legendWidth', 'layerDisplayed'],
    components: {
        InformationPanel, FilterForm, PopupInfo,
    },
    data: function() {
        return {
            enabledMode : null,
            responseData: [],
            queryMessage: "",
            msgTypeClass: "",
            curId : null,
            popupData: null,
            resultIds: null,
            resultsType: null,
            popupTitle: "",
            vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            hidePanel: false,
            threshSmallDisplay: threshSmallDisplay,
            filterArgs: null,
            groupLayer: null,
            defaultRadius: 20,
            circlePopup: null,
        }
    },
    watch: {
        linkedData() {
            if (this.enabledMode == "linked" && this.linkedCondition) this.linkedRequest();
        },
        popDisplayed() {
            if (!this.popDisplayed) this.cleanLayers();
        },
        linkedCondition() {
            const linked = document.getElementById("linked");
            if (linked) {
                if (!this.linkedCondition && linked.classList.contains("active")) {
                    this.enabledMode = null;
                }
            }
        },
    },
    computed: {
        linkedDataValue: {
            get() { return this.linkedData; },
            set(val) { this.$emit('update:linkedData', val);}
        },
        buttons(){
            return [
                { message: "Filter", id: "filter", img: require("@/assets/img/icons/filter_list-24px.svg"), tooltip:'Filter tool', func: this.filter, condition: true},
                { message: "Query circle", id: "circle", img:require("@/assets/img/icons/circle-24px.svg"), tooltip: 'Query circle tool',func: this.circle, condition: true},
                { message: "Distance", id: "distance", img: require("@/assets/img/icons/flag.svg"), tooltip: 'Distance tool', func: this.distance, condition: true},
                { message: "Measure",id: "measure", img: require("@/assets/img/icons/square_foot-24px.svg"), tooltip: 'Measure tool', func: this.measure, condition: true},
                { message: "Linked data", id: "linked", img:require("@/assets/img/icons/link.svg"), tooltip: 'Linked data tool',func: this.linked, condition: this.linkedCondition},
                { message: "Reset", id: "reset", img:require("@/assets/img/icons/reset.svg"), tooltip: 'Reset search',func: this.reset, condition: this.resetCondition},
            ];
        },
        activeButtons() {
            return this.buttons.filter(button => button.condition);
        },
        popDisplayed() {
            return this.layerDisplayed.includes('2020_HRSL')
        },
        linkedCondition() {
            return this.layerDisplayed.includes('agent')
        },
        resetCondition() {
            return (['linked'].includes(this.enabledMode) && this.linkedCondition) || ['filter'].includes(this.enabledMode)
        },
    },
    methods: {
        mount() {
            this.circleStat= L.circle([0, 0], {radius: 1000, draggable: true, zIndex:2000});
            this.circleStat.on('dragend', () => {
                this.filterRequest(this.$refs.filter.$data);
            });
            this.timeOutId = null;
            this.filterArgs = null;
            this.modeLayers = L.layerGroup();
            this.$parent.map.addLayer(this.modeLayers);
            window.onresize = () => { this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0); };
            const map = this.$parent.map;
            this.defaultCursor = map._container.style.cursor;
            map.on('click', e => this.onMapClick(e));
            map.on('touch', e => this.onMapClick(e));
            map.on('moveend', e => this.onMapMoveEnd(e));
            map.on('zoomend', e => this.onMapZoomEnd(e));
            map.on("popupopen", function(e) {
                setTimeout(() => { e.popup.update();}, 0);
            });
            window.addEventListener('keydown', (e) => {
                const contactModal = document.getElementById("contactModal");
                const isContactModal = contactModal.classList.contains("show");
                if (e.key == 'Escape' && this.enabledMode != null && !isContactModal) {
                    this.initTool(this.enabledMode, e);
                }
            });
        },

        initTool(mode, event, pointerType = null) {
            console.log(mode)
            this.resetState();
            if (this.enabledMode == mode && this.hidePanel) {
                this.hidePanel = false
                return;
            }
            if (this.enabledMode == "measure" || mode == "measure") this.toggleMeasure();
            if (this.enabledMode != "linked" && mode == "linked" && this.linkedCondition) this.linkedRequest();
            if (mode == "circle" && this.enabledMode != "circle") this.initCircle();
            else if (this.enabledMode == "circle") this.$parent.map.removeLayer(this.circleStat);
            const previous = document.getElementById(this.enabledMode);
            if (previous) previous.classList.remove("active");
            if (pointerType) document.getElementById('map').style.cursor = pointerType;
            else document.getElementById('map').style.cursor = this.defaultCursor;
            if (this.enabledMode == mode) {
                this.enabledMode = null;
                document.getElementById('map').style.cursor = this.defaultCursor;
                return;
            }
            event.target.classList.add("active");
            this.enabledMode = mode;
        },
        cleanLayers() {
            this.modeLayers.clearLayers();
            if (this.circlePopup) this.$parent.map.closePopup(this.circlePopup);
        },
        resetState() {
            this.cleanLayers();
            this.responseData.splice(0, this.responseData.length);
            this.resultIds = null;
            this.resultsType = null;
            this.filterArgs = null;
            this.linkBbox = null;
        },
        filter(event) {
            this.initTool("filter", event);
        },
        linked(event) {
            this.initTool("linked", event);
        },
        distance(event) {
            this.initTool("distance", event, 'crosshair');
        },
        measure(event) {
            this.initTool("measure", event);
        },
        circle(event) {
            this.initTool("circle", event);
        },
        initCircle() {
            const mapCenter = this.$parent.map.getCenter();
            const lat = mapCenter.lat;
            const lng = mapCenter.lng;
            const zoomCircle = 9;
            this.$parent.map.setView(new L.LatLng(lat, lng), zoomCircle);
            this.circleStat.setLatLng([lat, lng]);
            this.circleStat.setRadius(this.$refs.filter.radius * 1000);
            this.$parent.resetLayers();
            if (!this.$parent.map.hasLayer(this.circleStat)) {
                this.$parent.map.addLayer(this.circleStat);
            }
            this.filterRequest(this.$refs.filter.$data, this.defaultRadius);
        },
        toggleMeasure() {
            const measureButton = document.getElementsByClassName("leaflet-control-draw-measure")[0];
            measureButton.click();
        },

        changeRadius(radius) {
            if (!radius) return;
            if (this.enabledMode == "circle") {
                this.circleStat.setRadius(radius * 1000);
                this.filterRequest(this.$refs.filter.$data, radius);
            }
        },

        onMapClick(clickEvent) {
            const lat = clickEvent.latlng.lat;
            const lng = clickEvent.latlng.lng;
            switch(this.enabledMode) {
                case 'stats':
                    this.resetState();
                    // this.regionSelection(lng, lat);
                    break;
                case 'filter':
                    break;
                case 'circle':
                    break;
                case 'distance':
                    this.resetState();
                    this.distanceRequest(lng, lat);
                    break;
                default:
                    if (this.popDisplayed) this.showSettFromLatLng(lat, lng);
            }
        },

        onMapMoveEnd() {
            if (this.$parent.map.hasLayer(this.circleStat)){
                this.circleStat.dragging.enable();
            }
            if (["circle", "filter"].includes(this.enabledMode)) this.colorClusters();
        },

        onMapZoomEnd() {
            if (["circle", "filter"].includes(this.enabledMode)) this.colorClusters();
        },

        layerFromResponse(layerData, style, onEachFeature = null, pane = null) {
            const params = { style: style};
            if (onEachFeature != null) params.onEachFeature = onEachFeature;
            if (pane != null) params.pane = pane;
            const layer = L.geoJSON(layerData, params)
            this.modeLayers.addLayer(layer);
            return layer;
        },

        customMarkerFromResponse(popupname, data, isTooltip) {
            const params = {interactive: false};
            const mIcon = allMarkerStyle[popupname]['markerIcon']
            const mClass = allMarkerStyle[popupname]['markerClass']
            const mColor = allMarkerStyle[popupname]['polygonColor']
            params.pointToLayer = (_, latlng) => L.marker(latlng, {icon: mIcon});
            if (isTooltip) params.onEachFeature = (_, l) => l.bindTooltip(fillPopup(l, popupname), {direction: 'bottom', offset: L.point(-16, 24)});
            const iconCreateFunction = defIconClusterStyle(mClass);
            const polygonOptions = {color: mColor}
            let markers = L.markerClusterGroup(
                {'iconCreateFunction': iconCreateFunction,
                'polygonOptions': polygonOptions}
                );
            const layer = markers.addLayer(L.geoJSON(data, params));
            this.modeLayers.addLayer(layer);
        },

        linkedRequest() {
            this.resetState();
            if (this.linkedData) {
                if (!('nb_clients' in this.linkedData.properties)) return
                const name = "client";
                const parent = "agent or shop";
                const popupname = 'client';
                const poly_color = agentColor;
                let dashArray = null;
                this.$axios.get("/get_clients", {
                    params: this.linkedData
                }).then(response => {
                    if (Object.keys(response.data).length == 0)
                        throw `No ${name} linked to this ${parent} or no ${name} data.`
                    const data = response.data['points']['FeatureCollection'];
                    
                    let convex_hull = response.data['polygon']
                    const count = response.data['points']['count'];
                    const nbFeat = data.features.length;
                    const suff = name + (nbFeat > 1 ? 's' : '');
                    const detail = `(only ${nbFeat} geolocalized)`
                    this.handleResponseStatus(`${count} ${suff} found ${nbFeat == count ? "" : detail}`, null, 2000);
                    
                    this.customMarkerFromResponse(popupname, data, true);

                    const polygon = L.geoJSON(convex_hull, {
                        color: poly_color,
                        interactive: false,
                        dashArray: dashArray,
                        weight: 2,
                    })
                    this.modeLayers.addLayer(polygon);

                }).catch(error => this.handleResponseStatus(null, error, 3000));
            }
            this.linkedDataValue = null;
        },

        filterRequest(fields, radius = null) {
            this.resetState();
            const isCircle = this.enabledMode == "circle" || radius != null;
            const args = this.formatFilterArgs(fields, radius);
            if (this.vw < threshSmallDisplay) this.hidePanel = true;
            if (!isCircle) this.$toast.info('Applying search filter...');
            this.$axios.get('/filter', {
                params: args
            }).then(response => {
                const data = response.data;
                const nbFeat = data.nbFeat;
                const totalPop = data.totalPop;
                if (!data.features || nbFeat == 0)
                    throw "No results for those parameters. Try changing them."
                const suff = "settlement" + (nbFeat > 1 ? 's' : '');
                if (!isCircle)
                    this.handleResponseStatus(`${nbFeat} ${suff} found`, null, 2000);
                // all but query circle + settlement
                this.resultIds = data.features.flatMap(f => f.properties.id);
                this.filterArgs = args;
                if (isCircle) {
                    const feat = data.features[0];
                    let msg = `<p> <strong> ${nbFeat} </strong> ${suff} found</p>`;
                    if (totalPop) msg += `<p> <strong> ${d3.format(',.0f')(totalPop)} </strong> persons </p>`;
                    const displayExport = nbFeat > 0;
                    const position = Object.assign({}, this.circleStat.getLatLng());
                    // 110.574 permeits to convert km to lat
                    position.lat += this.circleStat.getRadius() / (110.574 * 1000);
                    this.circlePopup = L.popup({closeOnClick: false, autoClose: false})
                    .setLatLng(position)
                    .setContent(simpleSettPopup(msg, this.exportRequest, feat.id, displayExport))
                    .openOn(this.$parent.map);
                }
                this.markerClusterLayer(data.features);
            }).catch(error => this.handleResponseStatus(null, error, 3000));
        },

        formatFilterArgs(fields, radius = null) {
            const isCircle = this.enabledMode =="circle" || radius != null;
            const filtered = {...fields};
            const args = {};
            Object.entries(filtered).forEach( val => {
                if (Array.isArray(val[1])) args[val[0]] = JSON.stringify(val[1]);
                else args[val[0]]= val[1];
            }, {});
            if (radius) args.radius = radius;
            args.radius *= 1000;
            const bbox = this.$parent.map.getBounds();
            if (!isCircle) {
                args.bbox = bboxToWKT(bbox.getNorthWest(), bbox.getNorthEast(), bbox.getSouthWest(), bbox.getSouthEast());
                this.linkBbox = window.location.href;
                delete args.radius;
            }
            else {
                const coords = this.circleStat.getLatLng();
                args.lat = coords.lat;
                args.lng = coords.lng;
            }
            return args;
        },

        markerClusterLayer(results) {
            this.groupLayer = L.markerClusterGroup({
                iconCreateFunction: function(cluster) {
                    const children = cluster.getAllChildMarkers().map(c => c.feature.properties);
                    const value = children.reduce((prev, cur) => prev + cur.pop, 0);
                    const nb = d3.format(',.2s')(value);
                    return L.divIcon({ html: `<span value="${value}"> ${nb}</span>`, className: "agg-cluster-popup", iconSize: new L.Point(75, 30) });
                }
            });
            const markers = L.geoJSON(results, {
                pointToLayer : function(geoJsonPoint, latlng) {
                    return L.marker(latlng, {icon: clusterIcon});
                },
                onEachFeature : (feature, layer)  => {
                    const feat = feature.properties;
                    if (feat.cluster_size > 1) {
                    let msg = `<strong> ${feat.cluster_size} </strong> settlement(s) matching request around this point.`+ '<br>';
                    if (feat.cluster_size > maxSettToDisplay) msg += '<span class="alert">Too many settlements to display.</span>';
                    else msg += 'Click on an individual settlement to get informations';
                    const html = simpleSettPopup(msg, this.exportRequest, feat.id, false);
                    layer.bindPopup(html);
                    if (feat.cluster_size > maxSettToDisplay) return;}
                    layer.on('click', () => {
                        this.showSettlement(layer, feat.id, feat.box);
                    });
                }
            });
            this.groupLayer.addLayer(markers);
            this.modeLayers.addLayer(this.groupLayer);
            this.colorClusters();
        },
        colorClusters() {
            const clusters = document.getElementsByClassName("agg-cluster-popup");
            if (clusters == undefined) return
            const values = [];
            for (const element of clusters) {
                const value = element.childNodes[0].attributes.value.nodeValue;
                values.push(value);
            }
            const minValue = Math.min(...values);
            const maxValue = Math.max(...values);
            let linearScale = D3.scaleLinear()
                .domain([minValue, maxValue])
                .range(['#ffffb2', '#bd0026']);
            for (const element of clusters) {
                const value = element.childNodes[0].attributes.value.nodeValue;
                const color = linearScale(value);
                element.style.backgroundColor = color;
            }
        },
        showSettFromLatLng(lat, lng) {
            this.$axios.get('/get_sett', {
                params: {
                    point : pointToWKT(lng, lat),
                }
            }).then( response => {
                this.cleanLayers();
                let data = response.data;
                if (data.sett) data = data.sett;
                if (!data.geometry) return;
                const layer = this.layerFromResponse(data, styles.settContour);
                this.popupData = formatObject(data.properties);
                layer.bindPopup(this.$refs.popup.$el, {maxWidth: "auto"}).openPopup();
            });
        },
        showSettlement(layer, sett_id, box) {
            const bounds = L.latLngBounds(WKTToLatLngBounds(box));
            this.$axios.get('/show_sett', {
                params: {
                    sett_id : JSON.stringify(sett_id),
                }
            }).then( (response) => {
                const data = response.data;
                this.$parent.map.flyToBounds(bounds.pad(0.5));
                const nbSett = data.features.length;
                const onEachSett = (feature, settLayer) => {
                    settLayer.on( 'click', () => {
                        this.popupData = formatObject(feature.properties);
                        this.curId = feature.sett_id;
                        this.popupTitle = "Settlement";
                        settLayer.bindPopup(this.$refs.popup.$el, {maxWidth: "auto"}).openPopup();
                    })
                };
                if (nbSett == 1) {
                    this.popupData = formatObject(data.features[0].properties);
                    this.popupTitle = "Settlement"
                    this.curId = sett_id;
                    layer.bindPopup(this.$refs.popup.$el, {maxWidth: "auto"}).openPopup();
                }
                this.layerFromResponse(data, styles.settContour, nbSett > 1 ? onEachSett : null);
            });
        },

        exportRequest(ids) {
            if (!Array.isArray(ids)) ids = [ids];
            const args = {
                ids: ids,
                args: this.filterArgs,
            };
            if (this.enabledMode != "circle" && this.linkBbox != null) args.args.bboxUrl = this.linkBbox;
            startSpin(this.$parent.map);
            this.$toast.info('Results downloading...');
            this.$axios.post('/export', args,
            {
                responseType: 'blob'
            }).then(response => {
                const filename = response.headers['content-disposition'].split("filename=")[1];
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.click();
                window.URL.revokeObjectURL(url);
                stopSpin(this.$parent.map);
                this.$toast.clear();
                this.handleResponseStatus("Results dowloaded", null, 2000);
            }).catch(error => {this.handleResponseStatus(null, error, 3000); stopSpin(this.$parent.map);});
        },

        distanceRequest(lng, lat) {
            const point = pointToWKT(lng, lat);
            this.$axios.get('/distance', {
                params: {
                    wkt: point,
                }
            }).then(response => {
                this.responseData.push(formatDistance(response.data, "distance"));
                L.marker([lat, lng], {icon: distIcon}).addTo(this.modeLayers);
                this.layerFromResponse(response.data.grid.loc, {"color": '#2d75b5', "weight": 2, "opacity": 1});
                this.layerFromResponse(response.data.road.loc, {"color": "#838383", "weight": 2, "opacity": 1});
                this.layerFromResponse(response.data.fiber.loc, {"color": '#AAF2bF', "weight": 2, "opacity": 1});
                for (const popupname of ['client', 'agent']) {
                    this.customMarkerFromResponse(popupname, response.data[popupname].object, true)
                }    
                Object.entries(response.data).forEach(([k, v]) => {
                    let name = k;
                    if (k.slice(-1) == "s") name = k.slice(0, -1);
                    L.polyline([[lat, lng], v.closest.reverse()], {color: '#298000', dashArray:'10', className:'path-dist'})
                    .bindTooltip(`Distance to ${name}: ${formatter(v.dist)}m`, {sticky: true}).openTooltip()
                    .addTo(this.modeLayers);
                });
            }).catch(error => this.handleResponseStatus(null, error, 3000));
        },

        handleResponseStatus(success, err, timeMs) {
            this.$toast.clear();
            if (success) {
                this.toast = this.$toast.success(success);
                this.queryMessage = success;
                this.msgTypeClass = "success";
            }
            else if (err) {
                if (err.response) {
                    if (err.response.status == 500) this.queryMessage = "Internal server error. Please contact the Masae team for support.";
                    else this.queryMessage = err.response.data;
                }
                else this.queryMessage = err;
                this.toast = this.$toast.error(this.queryMessage);
                this.msgTypeClass = "error";
            }
            if (this.timeOutId) clearTimeout(this.timeOutId);
            this.timeOutId = setTimeout(() => {
                this.queryMessage = null;
                this.msgTypeClass = null;
            }, timeMs );
        },

        showFilter() {
            return this.enabledMode == "circle" || this.enabledMode == "filter";
        },
        reset() {
            this.resetState();
            this.linkedDataValue = null;
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '../style/scss/global';

    .content {
        position: absolute;
        width: auto;
        height: auto;
        top: calc(10vh + 2 * #{$tool-diameter} + 4 * #{$tool-margin-y});
    }
</style>
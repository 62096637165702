
const red = '#DE002B';
const greenSett = '#9CB227';
const mint = '#00A182';
const blue = "#6D9AFF";
const grey = "#5e5e5e"
const pointStyles = {
};

const colors = ["#f6f9a8", "#FFE724", "#FFBD2A", "#FF802A", "#FF210C"];


function getColor(value, steps) {
    const cols = colors;
    for (let i = steps.length - 1; i >= 0; i--) {
        if (value >= steps[i]) return cols[i]
    }
    return cols[0]
}

function getStyle(path, steps, propKey, layerKey) {
    const prop = path.feature.properties;
    const color = getColor(prop[propKey], steps);
    const style = styles[layerKey];
    style.fillColor = color;
    return style;
}

const styles = {
    "wards": {
        "color": mint,
        "weight": 2,
        "opacity": 0.3,
        "fill" : true,
        "fillOpacity" : 0
    },

    "grid" : {
        "color": '#2d75b5',
        "weight": 2,
        "opacity": 0.8,
        "fill" : true,
        "fillOpacity" : 0
    },
    "road" : {
        "color": grey,
        "weight": 1,
        "opacity": 1,
    },

    "region" : {
        "color": red,
        "weight": 2,
        "opacity": 0.8,
        "fill" : false,
    },
    
    "district" : {
        "color": blue,
        "weight": 2,
        "opacity": 0.8,
        "fill" : false,
    },
    
    "settContour" : {
        "color": greenSett,
        "weight": 2,
        "opacity": 0.6,
        "fill" : true,
        "fillOpacity" : 0.3
    },
};

['pop', 'nb_customer_a', 'nb_customer_b', 'pene_a', 'pene_b', 'repayment_a'].forEach( key => {
    styles[key] = {
        "color": grey,
        "weight": 2,
        "fill" : true,
        "fillOpacity": 0.8,
    }
});

const onEachFeat = {
}

export {styles, pointStyles, onEachFeat, getStyle, colors};
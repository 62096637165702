import * as d3 from 'd3-format';

// [d3 format, unit, full name, icon] 
const valuesFormat = {
    adm1:                 [null, null, "District", null],
    adm2:                 [null, null, "Region", null],
    crops:                [null, null, "Main crops cultivated", 'icon-wheat'],
    dist_agent :          [',.2f', ' km', 'Distance to nearest agent', 'icon-distance-agent'],
    dist_client :          [',.2f', ' km', 'Distance to nearest client', 'icon-users'],
    dist_grid:            [',.2f', ' km', 'Distance to electricity grid', 'icon-distance-elec'],
    dist_road:            [',.2f', ' km', 'Distance to nearest road', 'icon-car'],
    dist_fiber:           [',.2f', ' km', 'Distance to fiber network', 'icon-internet'],
    pop:                  [',.0f', null, 'Total population', 'icon-users'],
    poor:                 ['.0f', '%', '% of persons below the poverty threshold', 'icon-money'],
    under_15:             ['.0f', '%', '% of persons under 15 years old', 'icon-child'],
};

function format(key, value) {
    if (!(key in valuesFormat)) return [key, value];
    const formats = valuesFormat[key];
    let formatted = value;
    if (formats[0]) formatted = d3.format(formats[0])(value);
    if (formats[1]) formatted += formats[1];
    return [formats[2], { 'icon': formats[3], 'value': formatted }];
}

function formatObject(obj) {
    return Object.keys(obj).reduce( (prev,key) => {
        const [name, val] = format(key, obj[key]);
        prev[name] = val;
        return prev;
    }, {});
}

function formatDistance(data, name) {
    const formated = {
        name: name,
        data: []
    };
    formated.data = Object.entries(data).map(([k, v]) => format('dist_' + k, v.dist) );
    return formated;
}

export {formatObject, formatDistance};
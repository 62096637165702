<template>
    <div class="popup-info mx-2 my-3">
        <h2> {{ title }} </h2>
        <table>
        <tr v-for="(val, key) of filtered" :key="key">
            <td> 
                <icon v-if="val.icon" :name="val.icon"> </icon>
                {{ key }} 
            </td>
            <td align="right"> {{ val.value }} </td>
        </tr>
        </table>
        <button class="btn" @click="$emit('export-cell')"> 
            Export <icon :name="'icon-download'"/>
        </button>
    </div>
</template>
<script>

export default {
    name: 'cell-informations',
    props: {
        values: Object,
        title: String,
    },
    computed: {
        filtered() { 
            const filtered = {...this.values};
            delete filtered.priority;
            return filtered;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/popup.scss";
</style>

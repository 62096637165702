<template>
  <tippy :content="tooltipContent" :placement="tooltipPlacement"  :arrow="tooltipArrow" :animation="tooltipAnimation" :theme="tooltipTheme" >
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    viewBox="0 0 18 18" 
    :aria-labelledby="name" 
    role="presentation"
    >
    <g :fill="iconColor">
        <component :is="name"> </component>
    </g>
  </svg>
  </tippy>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'user'
    },
    width: {
      type: [Number, String],
      default: "1.2em"
    },
    height: {
      type: [Number, String],
      default: "1.2em"
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    tooltipContent: {
      type: String,
      default: ''
    },
    tooltipPlacement: {
      type: String,
      default: 'top'
    },
    tooltipAnimation: {
      type: String,
      default: 'scale'
    },
    tooltipTheme: {
      type: String,
      default: 'light'
    },
    tooltipArrow: {
      type: Boolean,
      default: true
    },
    },
  }
</script>

<style scoped>
svg {
    display: inline-block;
    vertical-align: text-bottom;
}
</style>
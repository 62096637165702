import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import config from '../config.json';
import Toaster from "@meforma/vue-toaster";
import Axios from 'axios';
import VueGtag from "vue-gtag";
import i18n from './i18n';

// import all styles
import './style/scss/global.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet-basemaps/L.Control.Basemaps.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'vue-slider-component/theme/default.css'
import 'vue-select/dist/vue-select.css';
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet-draw/dist/leaflet.draw.css';
import 'intro.js/introjs.css';

import 'bootstrap/dist/js/bootstrap.js';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css'
import 'tippy.js/themes/light.css'

const app = createApp(App).use(Toaster, {
    position: 'bottom', duration : 3000,
});
document.title = config["platform_name"];


// make icon system available globally
import IconBase from "@/components/IconBase";
app.component('icon', IconBase);
// add all icons
const req = require.context('@/assets/img/icons/', false, /\.vue$/);
req.keys().map(key => {
    const name = key.match(/\w+/)[0]; // remove extension
    return app.component(name, req(key).default) // register component
});

const axiosConfig = {
    baseURL : "/api"
};
app.config.globalProperties.$axios = Axios.create(axiosConfig);
app.config.globalProperties.$config = config;
app.config.globalProperties.$logout = () => fetch('/api/logout')
    .then(result => result.text())
    .then(() => {
        localStorage.removeItem("username");
        localStorage.removeItem("group");
        router.push('/login');
    });
    
app.use(i18n);
app.use(
    VueTippy,
    // optional
    {
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
        // componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
        defaultProps: {
            placement: 'auto-end',
            allowHTML: true,
        }, // => Global default options * see all props
    }
)
app.use(VueGtag, {
    config: { 
        id: config["ga_pid"],
        enabled: process.env.NODE_ENV === 'production'
    }
});
app.use(router).mount('#app');

<template>
    <div>
        <h2> 404 : Page not found </h2>
        <!-- <router-link to="/login">Back to login page</router-link> -->
    </div>
</template>
<script>

export default {
    name: 'not-found',
}
</script>

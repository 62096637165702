<template>
    <div id="info-panel">
        <Panel v-on:close-panel="$emit('close-panel')" v-if="values.length">
            <h1>{{ title }}</h1>
            <div v-if="values.length > 1" class="tab">
                <button v-for="(tab, index) in values" :key="tab.name" v-on:click.stop="changeTab(tab.name, index)" :class="{active: isActive(tab.name)}">
                    {{ tab.name }}
                </button>
            </div>
            <component :is="currentTabComponent" :values="values[dataIndex] || {}" :type="currentTab" class="content"></component>
        </Panel>
    </div>
</template>

<script>
import StatsInfo from '@/components/StatsInfo.vue';
import DistanceInfo from '@/components/DistanceInfo.vue';
import Panel from '@/components/Panel.vue';

const panelTitles = {
    "stats": "Population data",
    "distance": "Distances",
    "circle": "Query results",
}

export default {
    name: 'tabbed-panel',
    data: function() {
        return { 
            currentTab: null,
            dataIndex: 0,
        }
    },
    props: {
        type: String,
        values: Array,
        legendWidth: String,
    },
    watch: {
        values: {
            handler(now) {
                if (now.length > 0) this.currentTab = now[0].name;
            },
            deep: true
        },
        legendWidth() {
            const form = document.getElementById("info-panel");
            form.style.left = `calc(${this.legendWidth} + 40px)`;
        }
    },
    components: {
        StatsInfo, DistanceInfo, Panel
    },
    computed: {
        currentTabComponent: function() {
            switch(this.currentTab) {
                case 'settlement':
                case 'adm':
                    return "StatsInfo";
                case 'distance':
                    return "DistanceInfo";
                default:
                    return "";
            }
        },
        title: function() {
            return panelTitles[this.type] || "";
        },
    },
    methods: {
        changeTab: function(tab, index) {
            this.currentTab = tab;
            this.dataIndex = index;
        },
        isActive: function(name) {
            return name == this.currentTab;
        },
    }

}
</script>

<style lang="scss" scoped>

#info-panel {
    position: fixed;
    bottom: 14px;
    left: 40px;
    width: 400px;
    transition: left 1s;
}

</style>

import L from 'leaflet';
const token ="pk.eyJ1IjoiZ21vcmFpbiIsImEiOiJjamR1aTJubDQwMG1rMndyemZhamU1ZDBjIn0.NGwazEWDlIoGoKwAKteE0A";
const baseLayers = [
    L.tileLayer('//{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap contributors</a>, &copy; <a href="//carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 19,
        label: 'Dark'
    }),
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'mapbox/outdoors-v11',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Terrain'
    }),
    L.tileLayer('//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap contributors</a>, &copy; <a href="//carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 19,
        label: 'Light'
    }),
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'mapbox/streets-v11',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Streets'
    }),
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'mapbox/satellite-streets-v11',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a> <a href="https://www.maxar.com/" target="_blank">© Maxar</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Satellite'
    }),

];

export {baseLayers};
<template>
    <div v-if="values"> 
        <div>
            <div v-for="zone of values['zones']" :key="zone[0]">
                {{ zone[0] }} : {{ zone[1] }}
            </div>
        </div>
        <div class="section horizontal">
            <div class="gauge-wrapper" v-for="(stat, index) of values['stats']" :key="stat[0]">
                <VueSvgGauge
                :start-angle="-110" :end-angle="110" :value="stat[1] * 100" :separator-step="0" :min="0" :max="100" 
                :gauge-color="gaugeColors[index]"
                :scale-interval="0"
                :transition-duration="300"
                >
                    <div class="gauge-text">
                        <span> {{ (stat[1] * 100).toFixed(0) + '%' }} </span>
                    </div>
                </VueSvgGauge>
                <div> {{ stat[0] }} </div>
            </div>
        </div>
        <div class="section">
            <div v-for="data of values['data']" :key="data[0]">
                {{ data[0] }} : {{ data[1] }}
            </div>
        </div>
    </div>
</template>

<script>
import { VueSvgGauge } from 'vue-svg-gauge';

export default {
    name: 'statistics-informations',
    props: {
        values: Object,
        type: String,
    },
    data() {
        return {
            gaugeColors : ["#3378A5", "#33b49b", "#33b8d4"],
        }
    },
    components: {
        VueSvgGauge,
    },
}
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/statsInfo.scss";
</style>

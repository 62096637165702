<template>
    <div class="wrapper">
        <aside> 
            <a href="https://www.masae-analytics.com" target='_blank'> <img class="logo" src="@/assets/img/logo_masae.png" alt="Masae logo"> </a>
        </aside>
        <main class="login">
            <div class="title">
                <h1> Mapping Priority Areas for Energy Access Solutions in Nigeria</h1>
            </div>
            <div class="content-container">
                <div class="content">
                    <p class="text">
                        Masae’s mapping tool allows you to identify untapped areas for your off-grid electricity products by bringing together a wide variety of data sources. Its search, filter, visualization and export tools make it easy for your teams to exploit these layers and use them to optimize your sales efforts
                    </p>
                    <a href="/ng"> Discover the tool </a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>

export default {
    name: 'home'
};
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/home.scss";
</style>
<template>
<g>
    <path d="M 15.898438 3.601562 C 15.898438 3.570312 15.898438 3.539062 15.871094 3.511719 L 15.871094 3.480469 C 15.839844 3.449219 15.839844 3.421875 15.808594 3.390625 L 12.511719 0.0898438 C 12.480469 0.0585938 12.449219 0.0585938 12.421875 0.03125 L 12.390625 0.03125 C 12.359375 0.03125 12.328125 0 12.300781 0 L 2.398438 0 C 2.21875 0 2.101562 0.121094 2.101562 0.300781 L 2.101562 17.699219 C 2.101562 17.878906 2.21875 18 2.398438 18 L 15.601562 18 C 15.78125 18 15.898438 17.878906 15.898438 17.699219 Z M 14.878906 3.300781 L 12.601562 3.300781 L 12.601562 1.019531 Z M 4.199219 6.898438 L 5.398438 6.898438 L 5.398438 15.601562 L 4.199219 15.601562 Z M 4.351562 6.300781 L 4.800781 5.25 L 5.25 6.300781 Z M 4.199219 17.398438 L 4.199219 16.199219 L 5.398438 16.199219 L 5.398438 17.398438 Z M 7.5 17.398438 L 7.5 15.601562 L 8.699219 15.601562 L 8.699219 15 L 7.5 15 L 7.5 13.199219 L 8.699219 13.199219 L 8.699219 12.601562 L 7.5 12.601562 L 7.5 10.5 L 8.699219 10.5 L 8.699219 9.898438 L 7.5 9.898438 L 7.5 8.101562 L 8.699219 8.101562 L 8.699219 7.5 L 7.5 7.5 L 7.5 6 L 9.601562 6 L 9.601562 17.398438 Z M 10.199219 17.398438 L 10.199219 5.699219 C 10.199219 5.519531 10.078125 5.398438 9.898438 5.398438 L 7.199219 5.398438 C 7.019531 5.398438 6.898438 5.519531 6.898438 5.699219 L 6.898438 17.398438 L 6 17.398438 L 6 6.601562 C 6 6.511719 5.96875 6.421875 5.910156 6.390625 L 5.070312 4.410156 C 5.039062 4.289062 4.921875 4.230469 4.800781 4.230469 C 4.679688 4.230469 4.589844 4.289062 4.53125 4.410156 L 3.691406 6.390625 C 3.628906 6.449219 3.601562 6.511719 3.601562 6.601562 L 3.601562 17.398438 L 2.699219 17.398438 L 2.699219 0.601562 L 12 0.601562 L 12 3.601562 C 12 3.78125 12.121094 3.898438 12.300781 3.898438 L 15.300781 3.898438 L 15.300781 17.398438 Z M 10.199219 17.398438 "/>
    <path d="M 3.601562 1.5 L 6 1.5 L 6 2.101562 L 3.601562 2.101562 Z M 3.601562 1.5 "/>
    <path d="M 11.398438 6.601562 L 14.101562 6.601562 L 14.101562 7.199219 L 11.398438 7.199219 Z M 11.398438 6.601562 "/>
    <path d="M 11.398438 8.101562 L 14.101562 8.101562 L 14.101562 8.699219 L 11.398438 8.699219 Z M 11.398438 8.101562 "/>
    <path d="M 11.398438 9.601562 L 14.101562 9.601562 L 14.101562 10.199219 L 11.398438 10.199219 Z M 11.398438 9.601562 "/>
    <path d="M 11.398438 11.101562 L 14.101562 11.101562 L 14.101562 11.699219 L 11.398438 11.699219 Z M 11.398438 11.101562 "/>
    <path d="M 11.398438 12.601562 L 14.101562 12.601562 L 14.101562 13.199219 L 11.398438 13.199219 Z M 11.398438 12.601562 "/>
    <path d="M 11.398438 14.101562 L 14.101562 14.101562 L 14.101562 14.699219 L 11.398438 14.699219 Z M 11.398438 14.101562 "/>
    <path d="M 11.398438 15.601562 L 14.101562 15.601562 L 14.101562 16.199219 L 11.398438 16.199219 Z M 11.398438 15.601562 "/>
    <path d="M 3.601562 2.699219 L 8.398438 2.699219 L 8.398438 3.300781 L 3.601562 3.300781 Z M 3.601562 2.699219 "/>
</g>
</template>

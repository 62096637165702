<template>
  <div class="stats">
      <div class="container" v-for="val of values.data" :key="val[0]">
          <div class="key">{{ val[0] }}: </div>  <div class="value">{{ val[1].value }} </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'distance-informations',
    props: {
        values: Object,
    },
}
</script>

<style lang="scss" scoped>

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  justify-content: center;
  align-content: center;

  align-items: center;

  grid-auto-flow: row;
  grid-auto-rows: minmax(25px, min-content);
}

.key {
  justify-self: start;
}
.value {
  justify-self: end;
}

</style>
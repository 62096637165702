<template>
    <div class="legend mb-3">
        <span class="name"> {{ $t(title) }} </span>
        <div class="legend-row d-flex" v-for="cat in data" v-bind:key="cat.label">  
            <span v-if="type == 'marker'" class="legend-marker align-items-center" v-html="cat.marker.options.html"></span>
            <span v-else class="legend-color align-items-center" :style="`background: ${cat.color}; ${lineStyle};`"></span>
            <label class="align-items-center"> {{ $t(cat.label) }} </label>
        </div>
        <div v-if="notes" class="text-start mt-3"> 
            <div v-html="notes"></div>
        </div> 
        <div class="separator pt-3 border-bottom"></div>
    </div>
</template>


<script>

export default {
    name: 'Legend',
    props: {
        title: String,
        data: {
            type: Array,
            default: function() { [{label: "Empty", color: "#000000"}] },
            required: true,
            validator: function (values) {
                return values.length > 0 && values.every(v => v.label != null && (v.color != null || v.marker != null));
            }
        },
        notes: String,
        type: {
            default: "standard",
            type: String
        },
    },
    computed: {
    lineStyle() {
            if (this.type == "line") return "height: 2px; border: none;"
            else return ""
        }
    },

}
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/legend.scss";
</style>


import * as d3 from 'd3-format';
import chroma from "chroma-js";
import { ckmeans } from "simple-statistics";
import { pick } from 'lodash';
import L from 'leaflet';

import { allMarkerStyle } from '@/util/markers.js';

const haloFilter = `<filter id="halo">
<feMorphology in="SourceGraphic" result="DILATED" operator="dilate" radius="1"> </feMorphology>
<feColorMatrix type="matrix" in="DILATED" result="COLORED" values="
                                                0 0 0 0 1
                                                0 0 0 0 1
                                                0 0 0 0 1
                                                0 0 0 1 0"></feColorMatrix>
<feBlend in="COLORED" in2="SourceGraphic" mode="overlay" out="CLIPPED"> </feBlend>
</filter>`;

const red = "#FF210C";
const lightYellow = "#ffff83";
const grey = "#838383";
const greenSett = '#9CB227';
const darkGrey = "#6e6e6e";
const blue = "#abbcea";


const transparentStyle = {
    color: "#000000",
    weight: 0,
    opacity: 0,
    fillOpacity: 0,
};

function markerLayer(layerName, notes="") {
    return {
        name: layerName,
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: "",
                        data: [
                            {marker: allMarkerStyle[layerName]["markerIcon"], label:layerName},
                        ],
                        notes: notes,
                        type: "marker",
                    },
                }
            },
        },
    };
}

const bizLayers = [['pop', 'Population'],  ['nb_customer_a', 'BNA'], ['nb_customer_b', 'NBB'], 
    ['pene_a', 'PENA'], ['pene_b', 'PENB'], ['repayment_a', 'REPA']];


const penRepaymentBase = {
    baseStyle: { // used for static styling
        color: grey,
        weight: 2,
        fill : true,
        fillOpacity: 0.8,
        defaultSelectedProp: '2020_HRSL',
    },
    legend: {
        default: {
            colors: {
                type: 'quantile',
                colors: [lightYellow, red],
                nbColor: 5, 
            },
            formatting: ',.4f' // dict prop -> format, or string (used for all props)
        }
    }
};

const bizLayerDefs = bizLayers.map(([name, verboseName]) => {
    const def = JSON.parse(JSON.stringify(penRepaymentBase));
    def.name = name;
    def.legend.title = verboseName;
    def.baseStyle.defaultSelectedProp = name;
    if (name.includes('customer') || name == 'pop') def.legend.default.formatting=',.2s';
    return def;
});

const layerDefinitions = [
    ...bizLayerDefs,
    markerLayer("agent"),
    markerLayer("client"),
    {
        name: "wards",
        baseStyle: {
            "color": '#00A182',
            "weight": 2,
            "opacity": 0.3,
            "fill" : true,
            "fillOpacity" : 0
        }
    },
    {
        name: "grid",
        baseStyle : {
            "color": '#2d75b5',
            "weight": 2,
            "opacity": 0.8,
            "fill" : true,
            "fillOpacity" : 0
        },
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: '',
                        data: [
                            {color: "#2d75b5", label:"Grid lines"},
                        ],
                        type: "line",
                    },
                }
            },
        },
    },
    {
        name: "fiber",
        baseStyle : {
            "color": '#AAF2bF',
            "weight": 2,
            "opacity": 0.8,
            "fill" : true,
            "fillOpacity" : 0
        },
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: '',
                        data: [
                            {color: "#AAF2bF", label:"Fiber"},
                        ],
                        type: "line",
                    },
                }
            },
        },
    },
    {
        name: "road",
        baseStyle: {
            "color": grey,
            "weight": 1,
            "opacity": 1,
        },
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: '',
                        data: [
                            {color: grey, label:"Roads"},
                        ],
                        type: "line",
                    },
                }
            },
        },
    },
    {
        name: "region",
        baseStyle: {
            "color": red,
            "weight": 1,
            "opacity": 0.8,
            "fill" : false,
        },
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: '',
                        data: [
                            {color: red, label:"Regions boundaries"},
                        ],
                        type: "line",
                    },
                }
            },
        },
    },
    {
        name: "district",
        baseStyle: {
            "color": blue,
            "weight": 1,
            "opacity": 0.8,
            "fill" : false,
        },
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: '',
                        data: [
                            {color: blue, label:"District boundaries"},
                        ],
                        type: "line",
                    },
                }
            },
        },
    },
    {
        name: "settContour",
        baseStyle: {
            "color": greenSett,
            "weight": 2,
            "opacity": 0.6,
            "fill" : true,
            "fillOpacity" : 0.3

        }
    },
    {
        name: "none",
        baseStyle: transparentStyle,
    },

    {
        name: '2020_HRSL',
        legend: {
            default: {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: 'Raster population',
                        data: [
                            {color: "#FFFFB2", label:"Less"},
                            {color: "#FD8D3C", label:""},
                            {color: "#BD0026", label:"More"},
                        ],
                    },
                },

            }
        }
    },
    {
        name: "2G",
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: 'Mobile coverage - 2G',
                        data: [
                            {color: "#CDB6EA", label:"low"},
                            {color: "#756BB1", label:"medium"},
                            {color: "#54288F", label:"hight"},
                        ],
                        notes: '<b>Source:</b> GSMA - 2019'
                    },
                }
            },
        },
    },
    {
        name: "3G",
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: 'Mobile coverage - 3G',
                        data: [
                            {color: "#CDB6EA", label:"low"},
                            {color: "#756BB1", label:"medium"},
                            {color: "#54288F", label:"hight"},
                        ],
                        notes: '<b>Source:</b> GSMA - 2019'
                    },
                }
            },
        },
    },
    {
        name: "4G",
        legend: {
            'default': {
                colors: {
                    type: 'predefined',
                    legendContent: {
                        title: 'Mobile coverage - 4G',
                        data: [
                            {color: "#CDB6EA", label:"low"},
                            {color: "#756BB1", label:"medium"},
                            {color: "#54288F", label:"hight"},
                        ],
                        notes: '<b>Source:</b> GSMA - 2019'
                    },
                }
            },
        },
    },
];


// return interval steps from the given values
function getSteps(values, nbColors, method = 'jenks') {
    if (values.length === 0) return null
    // const vals = [...new Set(values)];
    let vals = values;
    const nbValues = vals.length;
    const hasNull = vals.includes(null);
    if (hasNull) vals = vals.filter(v => v != null);
    vals = vals.filter(v => v != undefined);
    let steps = null;
    if (method == 'quantile') {
        vals.sort((a, b) => a - b);
        // handle extreme cases where too many similar values exist (more than 20% of the data)
        // breaks the properties of quantile separation, but at least displays more readable values
        let i = 0;
        const minPart = Math.floor(nbValues * 0.20);
        while( i < vals.length - minPart) {
            if (vals[i] == vals[i + minPart]) {
                vals =  [...new Set(vals)];
                break;
            }
            else i += minPart;
        }
        const intervalSize = Math.floor(vals.length / nbColors);
        steps = [...Array(nbColors).keys()].map(i => vals[i * intervalSize]);
        steps = steps.filter((val, index) => {
            if (val === undefined) return false;
            if (index < (steps.length - 1) && val == steps[index + 1]) return false;
            return true;
        });
    }
    else if (method == 'jenks') {
        steps = ckmeans(vals, nbColors).map(cluster => cluster[0]);
    }
    if (hasNull) steps.splice(0, 0, null);
    return steps;
}

// returns a formatted legend object from provided steps
// the first element of steps is the minimal value, so won't be displayed in the legend
function stepsToLegend(steps, name, colors, notes = null, formatter = null) {
    const legend = [];
    if (steps[0] == null) {
        steps.splice(0, 1);
        colors.splice(0, 1);
        legend.push({ color: darkGrey, label: 'N/A' });
    }
    const coloredSteps = steps.map((s, i) => [s, colors[i]]);
    const mean = steps.reduce((acc, cur) => acc + cur, 0) / steps.length;
    let f = d3.format(formatter ? formatter : (mean < 5 ? ',.4f' : ',.2s'));
    for (let i = coloredSteps.length - 1; i >= 0; --i) {
        const cur = coloredSteps[i];
        if (i == coloredSteps.length - 1) {
            legend.push({ color: cur[1], label: `> ${f(cur[0])}` });
            continue;
        }
        const prev = coloredSteps[i + 1];
        if (!i) legend.push({ color: cur[1], label: `< ${f(prev[0])}` });
        else legend.push({ color: cur[1], label: `${f(cur[0])} - ${f(prev[0])}` });
    }
    return { title: name, data: legend, notes: notes };
}

function mappingToLegend(mapping, title, notes = null) {
    const data = Object.entries(mapping).map(([cat, color]) => {
        return { color: color, label: cat };
    });
    return { title: title, data: data, notes: notes };
}

function constructTabbedInfos(properties, component, title = null) {
    component.$data.infoValues = properties;
    if(title) component.$data.infoTitle = title;
    return component.$refs.infos.$el;
}

class Layer {
    constructor(layerName, leafletLayer) {
        const def = layerDefinitions.find(l => l.name == layerName);
        if (def === undefined) {
            console.error(`Error: layer named ${layerName} not found in definitions`);
            return;
        }
        this.def = def;
        this.legend = null;
        this.leafletLayer = leafletLayer;
        this.isDisplayed = false;
        this.tabbedInfos = null;
        this.labelsGroup = L.layerGroup();
        if (this.def.filtering) this.initFilters();
    }

    // retrieves a color from the given value and steps
    getColor(value, steps, colors) {
        if (value == null && steps[0] == null) return colors[0];
        for (let i = steps.length - 1; i > 0; i--) {
            if (value >= steps[i]) return colors[i]
        }
        return colors[0]
    }

    addTo(map) {
        if (!this.map) this.map = map;
        this.isDisplayed = true;
        this.leafletLayer.addTo(map);
        this.setStyle();
        this.filters = this._filters;
    }

    remove(map) {
        this.isDisplayed = false;
        map.removeLayer(this.leafletLayer);
        this.legend = null;
        this.filters = null;
        this.labelsGroup.remove();
    }

    getStyleFromSteps(value, steps, colors) {
        const color = this.getColor(value, steps, colors);
        const style = this.def.baseStyle;
        style.fillColor = color;
        return style;
    }

    getStyleFromMapping(value, mapping) {
        const style = this.def.baseStyle;
        const color = mapping[value];
        style.fillColor = color;
        return style;
    }

    getUniquePropValues(propKey) {
        const propValues = new Set();
        this.leafletLayer.eachLayer(path => {
            if (!path.feature.properties[propKey]) return;
            propValues.add(path.feature.properties[propKey]);
        });
        return propValues;
    }

    bindInfos(path, infoDefs) {
        if (!infoDefs) return;
        infoDefs.forEach(infoDef => {
            let props;
            if (Array.isArray(infoDef.props)) props = pick(path.feature.properties, infoDef.props);
            else if (infoDef.type != 'label') props = path.feature.properties;
            const title = infoDef.titleProp ? path.feature.properties[infoDef.titleProp] : null;
            if (infoDef.type == 'popup') path.bindPopup(() => constructTabbedInfos(props, this.component, title));
            else if (infoDef.type == 'tooltip') path.bindTooltip(() => constructTabbedInfos(props, this.component, title));
            else if (infoDef.type == 'label') {
                if (path._label) return;
                const center = path.getBounds().getCenter();
                const label = new L.CircleMarker(center, {radius: 1, opacity: 0, fillOpacity: 0} )
                    .bindTooltip(title, {permanent: true, opacity: 1, className: "map-label", direction: "center", offset: [0, 0] })
                    .addTo(this.labelsGroup);
                path._label = label;
            }
        });
    }

    hideLabelsSmallPoly() {
        this.leafletLayer.eachLayer(path => {
            if (!path._label || !path._path) return;
            const polyBounds = path._path.getBoundingClientRect();
            const labelBounds = path._label._tooltip._container.getBoundingClientRect(); 
            const included = polyBounds.x <= labelBounds.x && polyBounds.right >= labelBounds.right 
                            && polyBounds.y <= labelBounds.y && polyBounds.bottom >= labelBounds.bottom;
            if (!included) path._label._tooltip._container.classList.add('hidden');
            else path._label._tooltip._container.classList.remove('hidden');
        });
    }

    setGlobalStyle(style=this.def.baseStyle) {
        this.leafletLayer.setStyle(style);
    }

    setStyle(selectedProp) {
        if (!this.isDisplayed) return;
        if (!selectedProp) selectedProp = this.def.baseStyle?.defaultSelectedProp || null;
        if (this.def.legend && (this.def.legend[selectedProp] || this.def.legend.default)) {
            const legend = this.def.legend[selectedProp] || this.def.legend.default;
            const notes = legend.notes;
            const colors = legend.colors;
            const infoDefs = this.def.info;
            if (['jenks', 'quantile', 'match-label', 'predefined-range'].includes(colors.type) && !selectedProp) {
                console.log('Error: a baseStyle with a defaultSelectedProp should be defined for computed colors');
                return;
            }
            if (colors.type == 'jenks' || colors.type == 'quantile') {
                const values = [];
                this.leafletLayer.eachLayer(path => { values.push(path.feature.properties[selectedProp]) });
                const steps = getSteps(values, colors.nbColor, colors.type);
                const stepsWithoutNull = steps[0] == null ? steps.slice(1) : steps;
                const palette = chroma.scale(colors.colors).mode('lrgb').colors(stepsWithoutNull.length);
                if (steps[0] == null) palette.splice(0, 0, darkGrey)
                this.leafletLayer.eachLayer(path => {
                    path.setStyle(this.getStyleFromSteps(path.feature.properties[selectedProp], steps, palette));
                    this.bindInfos(path, infoDefs)
                });
                let formatting = legend.formatting;
                if (typeof(formatting) == 'object') {
                    formatting = formatting[selectedProp] || ',.0f';
                }
                this.legend = stepsToLegend(steps, selectedProp, palette, notes, formatting);
            } else if (colors.type == 'match-label') {
                const values = Array.from(this.getUniquePropValues(selectedProp)).sort();
                const mapping = chroma.scale(colors.colors).mode('lrgb').colors(values.length).reduce((mapping, current, index) => {
                    mapping[values[index]] = current;
                    return mapping;
                }, {});
                this.leafletLayer.eachLayer(path => {
                    path.setStyle(this.getStyleFromMapping(path.feature.properties[selectedProp], mapping));
                    this.bindInfos(path, infoDefs);
                });
                this.legend = mappingToLegend(mapping, selectedProp, notes);
            } else if (colors.type == 'predefined-range') {
                const palette = chroma.scale(colors.colors).mode('lrgb').colors(colors.ranges.length + 1);
                this.leafletLayer.eachLayer(path => {
                    path.setStyle(this.getStyleFromSteps(path.feature.properties[selectedProp], colors.ranges, palette));
                    this.bindInfos(path, infoDefs);
                });
                let formatting = legend.formatting;
                if (typeof(formatting) == 'object') {
                    formatting = formatting[selectedProp] || ',.0f';
                }
                this.legend = stepsToLegend(colors.ranges, selectedProp, palette, notes, formatting);
            } else if (colors.type == 'predefined') {
                this.legend = colors.legendContent;
                if (this.def.baseStyle) {
                    this.leafletLayer.eachLayer(path => {
                        path.setStyle(this.def.baseStyle);
                    });
                }
            }
        } else if (this.def.baseStyle) {
            this.leafletLayer.eachLayer(path => {
                path.setStyle(this.def.baseStyle);
            });
        }
        if (this.leafletLayer.eachLayer) {
            this.leafletLayer.eachLayer(layer => {
                layer._originalStyle = {...layer.options }; // register original style for restoring from filtering
            });
        }
        if (this.labelsGroup.getLayers().length) {
            if (!this.map.hasLayer(this.labelsGroup)) this.map.addLayer(this.labelsGroup);
            this.hideLabelsSmallPoly();
            this.map.on('moveend', () => {
                this.hideLabelsSmallPoly();
            });
        }
        if (this.filters) this.filter(true);
    }

    // init _filters, which is in object in the form of 
    // { propKey : {
    //         active: Boolean,
    //         value: filterValue,
    //         type: filterType
    //     }
    // }
    // by default, string prop = text field, int prop = range field
    // it can be overloaded in the 'filtering' definition, e.g Vulnerability: 'dropdown'
    initFilters() {
        const filters = {};
        this.leafletLayer.eachLayer(layer => {
            Object.entries(layer.feature.properties).forEach(([propKey, propValue]) => {
                const typeProp = typeof(propValue);
                if (!filters[propKey]) {
                    filters[propKey] = { type: typeProp, active: false };
                    const filterType = this.def.filtering[propKey];
                    if (typeProp == 'number')
                        filters[propKey].value = [null, null]; // init range
                    else if (typeProp == 'string') {
                        filters[propKey].value = null;
                        if (filterType == 'dropdown') {
                            filters[propKey].choices = this.getUniquePropValues(propKey);
                        }
                    }
                }
            });
        });
        this._filters = filters;
    }

    filter() {
        this.leafletLayer.eachLayer(layer => {

            let filteredOut = false;
            Object.entries(this.filters).forEach(([propKey, filter]) => {
                const propValue = layer.feature.properties[propKey];
                if (filter.active && (
                        (filter.type == 'number' && typeof(propValue) == 'number' &&
                            (
                                (filter.value[0] != null && propValue < filter.value[0]) ||
                                (filter.value[1] != null && propValue > filter.value[1]))
                        ) ||
                        (filter.type == 'string' && typeof(propValue) == 'string' &&
                            filter.value != null &&
                            !propValue.toLowerCase().includes(filter.value.toLowerCase())
                        )
                    )) {
                    filteredOut = true;
                    return;
                }
            });
            this.setFilterLayer(layer, filteredOut)
        });
    }

    resetFilter(propKey) {
        if (Array.isArray(this.filters[propKey])) this.filters[propKey] = [null, null];
        else this.filters[propKey] = null;
    }


    setFilterLayer(layer, filtered=true, ) {
        if (filtered) {
            layer.setStyle(transparentStyle);
            if (layer.getTooltip() !== undefined) layer.unbindTooltip();
            if (layer.getPopup() !== undefined) layer.unbindPopup();
            if (layer._label) layer._label.closeTooltip();
        }
        else {
            layer.setStyle(layer._originalStyle);
            this.bindInfos(layer, this.def.info);
            if (layer._label) layer._label.openTooltip();
        }
    }
}

class LayerCollection {
    constructor(mapComponent) {
        this.component = mapComponent;
        this.map = mapComponent.map;
        this.layers = [];
        this.layerDisplayed = {}; // a groupName => [layerDisplayed] dict containing currently displayed layers
        if (!document.getElementById('halo')) {
            let svgDefs = document.getElementById('svg-definitions');
            if (!svgDefs) return console.log('There should be an svg element containing a <defs> element named "svg-definitions" in the document');
            svgDefs.insertAdjacentHTML('beforeend', haloFilter);
        }
    }

    // "layer" param is a Layer instance
    addLayer(layer, display = true, layerGroup = null) {
        layer.component = this.component;
        if (display) layer.addTo(this.map);
        this.layers.push(layer);
        if (layerGroup && display) this.addLayerToGroup(layer.def.name, layerGroup);
        return layer;
    }

    displayLayer(layerName, layerGroup = null) {
        const layer = this.layers.find(l => l.def.name == layerName);
        if (!layer) return false;
        if (this.map.hasLayer(layer.leafletLayer)) return true;
        layer.addTo(this.map);
        this.addLayerToGroup(layerName, layerGroup);
        return true;
    }

    addLayerToGroup(layerName, groupName) {
        const layerIndex = this.layers.findIndex(l => l.def.name == layerName);
        if (!this.layerDisplayed[groupName]) {
            this.layerDisplayed[groupName] = [layerIndex];
        } else {
            this.layerDisplayed[groupName].push(layerIndex);
        }
    }

    clearLayerGroup(groupName) {
        if (!this.layerDisplayed[groupName]) return;
        this.layerDisplayed[groupName].forEach(layerIndex => {
            this.layers[layerIndex].remove(this.map);
        });
        this.layerDisplayed[groupName] = [];
    }

    removeLayer(layerName) {
        const layer = this.layers.find(l => l.def.name == layerName);
        if (!layer) {
            console.log(`layer with name ${layerName} not found`);
            return;
        }
        layer.remove(this.map);
    }

    performFilter(layerName) {
        const layer = this.getLayer(layerName);
        layer.filter();
    }

    updateFilterActive(layerName, prop, activeState) {
        const layer = this.getLayer(layerName);
        layer._filters[prop].active = activeState;
    }

    getLegends() {
        return this.layers.map(layer => layer.legend).filter(l => l);
    }

    getFilters() {
        return this.layers.filter(layer => layer.filters).map(layer => {
            return {
                layerName: layer.def.name,
                filters: layer.filters
            }
        });
    }

    getLayer(layerName) {
        const layer = this.layers.find(l => l.def.name == layerName);
        if (!layer) {
            console.log(`layer with name ${layerName} not found`);
            return;
        }
        return layer;
    }

}

export { Layer, LayerCollection }
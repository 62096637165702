<template>
    <div class="panel">
        <icon class="close" :name="'icon-close'" :height="'17px'" :width="'17px'" :iconColor="'#000'" @click="$emit('close-panel')"/>
        <icon v-if="onInfo != ''" id="icon-info" v-on:click="$emit(onInfo)" class="info" :name="'icon-info'" :height="'17px'" :width="'17px'" :iconColor="'#000'"/>
        <div class="panel-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'generic-panel',
    props: {
        onInfo: {
            type: String,
            default: ""
        },
    },
}
</script>

<style lang="scss">
@import "@/style/scss/components/panel.scss";
</style>

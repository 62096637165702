
function openLayerSelector(layerName) {
    const el1 = document.getElementById(layerName + 'menu');
    const el2 = document.getElementById(layerName + 'icon');
    if (el1.classList.contains("hide")) {
        el1.classList.remove("hide");
        el2.classList.add("active");
    }
}

function closeLayerSelector(layerName) {
    const el1 = document.getElementById(layerName + 'menu');
    const el2 = document.getElementById(layerName + 'icon');
    if (el2.classList.contains("active")) {
        el1.classList.add("hide");
        el2.classList.remove("active");
    }
}

function baseTutoSteps() {
    return  [
        {
            intro: "<h2> Masae energy demo platform </h2> <p> Welcome to the online tutorial </p> <p> This tour will help you discover the main features of the application </p>",
        },

        // ---- LAYERS ----
        {
            element: '.layer-selector',
            intro: "<div> First, let's review the available layers. </div>",
            position: 'left',
            onbeforechange: () => {
                closeLayerSelector('info')
            },
        },
        {
            element: '.layer-info',
            intro: "<div> The 'Information layers' are the layers with generic informations about the country. </div>",
            position: 'left',
            onbeforechange: () => {
                openLayerSelector('info')
                closeLayerSelector('business')
            },
        },
        {
            element: '.layer-business',
            intro: "<div> The 'Business layers' are the layers generated from fake business data of a dummy off-grid actor. </div>",
            position: 'left',
            onbeforechange: () => {
                closeLayerSelector('info')
                openLayerSelector('business')
            },
        },
        {
            element: '.basemaps.leaflet-control',
            intro: "<div> This menu lets you switch between several map backgrounds, giving some elements a better visibility (e.g. settlements over a dark basemap). </div>",
            position: 'left',
            onbeforechange: () => {
                closeLayerSelector('business')
            },
        },


        // --- TOOLS ----
        {
            element: '.tools',
            intro: "<div> Now let's review the available tools. </div>",
            position: 'left',
            onafterchange: () => {  
                const f = document.getElementById('filter')
                if (f.classList.contains('active')) f.click(); 
            },
        },
        {
            element: '#filter',
            intro: "<div> This is the 'Filter tool'. It will help identify points of interest. </div>",
            position: 'left',
            onafterchange: () => {  
                const f = document.getElementById('filter')
                if (!f.classList.contains('active')) f.click(); 
            },
        },
        {
            element: '.panel',
            intro: "<div> This is the main form of the filter tool. It will help you make queries on settlements. <br><br>Settlements are groups of dwellings, irrespective of administrative boundaries, which may contain information attributes like population. </div>",
        },
        {
            element: '#filterPopulation',
            intro: "<div> To modify one of the filter criteria, change the value in the text box. </div>",
            onafterchange: () => {  
                const f = document.getElementById('filterPopulation')
                f.value = 6000; 
                const slider = document.getElementById('isRoad')
                if (!slider.checked) slider.click(); 
            },
        },
        {
            element: '#isRoad',
            intro: "<div> To remove or add one of the filter criteria, toggle the slider. </div>",
            onafterchange: () => {  
                const slider = document.getElementById('isRoad')
                if (slider.checked) slider.click(); 
            },
        },
        {
            element: '.advanced-card',
            intro: "<div> To access advanced filter criteria, click on the 'Advanced filters' box. </div>",
            onafterchange: () => {
                const reset = document.getElementById('reset')
                reset.click(); 
            },
        },
        {
            element: '#filter-buttons',
            intro: "<div> When the filters are set, the settlements that meet the requirements can be displayed on the map by clicking the 'Filter' button.</div> <div> Feel free to explore the results on the map. You can export them by clicking on the 'Export' button. </div>",
            onafterchange: () => {  
                const f = document.getElementById('filterQuery')
                f.click(); 
            },
        },
        {
            element: '#reset',
            intro: "<div> To reset the previous filter request, click on the reset button. </div>",
            position: 'left',
            onafterchange: () => {  
                const f = document.getElementById('reset')
                f.click(); 
            },
        },
        {
            element: '#icon-info',
            intro: "<div> For a more comprehensive tutorial on searching settlements and exporting results, please use the 'Information' button in the top-right of this panel. </div>",
        },
        {
            element: '#circle',
            intro: "<div> The 'Query circle tool' is similar to the 'filter tool', but allows for a better granularity of the search area. <br><br>It lets you filter on a circular area drawn on the map. The circle is draggable and its radius is customizable. </div>",
            position: 'left',
            onbeforechange: () => {
                const f = document.getElementById('filter');
                f.click(); 
            },
        },
        {
            element: '#distance',
            intro: "<div> The 'distance tool' computes the distance from a point anywhere on the map to the closest points of interest (grid, road, fiber network, client and agent). </div>",
            position: 'left',
        },
        {
            element: '#measure',
            intro: "<div> 'Map measure' lets you draw one or several line segments, displaying each segment length together with the cumulative length. </div>",
            position: 'left',
        },
        {
            element: '#businessagents',
            intro: "<div> When the 'Agents' layer is displayed on the map, another tool is available : the 'Linked data tool'. </div>",
            position: 'left',
            onbeforechange: () => {
                openLayerSelector('business');
                const f = document.getElementById('businessagents')
                f.click(); 
            },
        },
        {
            element: '#linked',
            intro: "<div> When the tool is active and you click on an agent displayed on the map, it displays all the customers to whom the agent has sold a product. </div>",
            position: 'left',
        },

        // --- MENU ---
        {
            element: '#homeicon',
            intro: `<div> If you want to go to home page, click the 'Home' button. </div>`,
            position: 'top',
        },
        {
            element: '#tutorialicon',
            intro: `<div> If you want to replay this tutorial, click the 'Tutorial' button. </div>`,
            position: 'top',
        },
        {
            intro: `<div> This introduction is now over, thank you for following it. If you have any question, please send the Masae team <a href="mailto:support@masae-analytics.com"> an e-mail.</a> </div>`,
            onbeforechange: () => {
                const f = document.getElementById('businessagents')
                f.click(); 
                closeLayerSelector('business');
            },
        }
    ];
}


function caseStudySteps(opts, map, inputForm) {
    return  [
        {
            element: '.panel',
            intro: `<div> Let’s do a practical use case on filtering the settlements. For example, let’s look for settlements that :
            <ul>
            <li> Have more than 1000 inhabitants </li>
            <li> And is more than 2km away for the electricity grid </li>
            </ul>
            </div>
            `,
            position: 'right',
        },
        {
            element: '#map',
            
            intro: `<div> Let’s say we want to find these areas in the north east of the country: let's pan the map to see this region. </div>`,
            onbeforechange: () => {
                map.setView(opts.center, opts.zoom);
            }
        },
        {
            element: '#filterPopulation',
            modifier: (el) => el.parentNode,
            intro: `<div> We want fairly densely populated areas, with at least ${opts.pop} people, so we type ${opts.pop} in the <i>Minimum inhabitant count</i> field. </div>`,
            position: 'right',
            onbeforechange: () => {
                inputForm.filterPopulation = opts.pop;
            }
        },
        {
            intro: `<div> Let’s also narrow it down to areas more than ${opts.dist_grid}km away from the electricity grid. </div>`,
            element: '#filterDistGrid',
            position: 'right',
            modifier: (el) => el.parentNode,
            onbeforechange: () => {
                inputForm.filterDistGrid = opts.dist_grid;
            }
        },
        {
            element: '.panel',
            intro: `<div> Then we uncheck all the criteria we don't want taken into account. </div>`,
            position: 'right',
            onbeforechange: () => {
                document.getElementById('isCrop').click(); 
                document.getElementById('isAgent').click(); 
                document.getElementById('isRoad').click(); 
                document.getElementById('isFiber').click(); 
                document.getElementById('isPoverty').click(); 
            }
        },
        {
            intro: `<div> Clicking on the <i>filter</i> button starts the query... and displays the results. </div>`,
            element: '#map',
            onbeforechange: () => {
                document.getElementById('filterQuery').click();
            }
        },
        {
            intro: `<div> By clicking on a settlement, we can access various information about it, and also export it. </div>`,
            element: '#map',
            onbeforechange: () => {
                for (let i in map._layers) {
                    const layer = map._layers[i];
                    if (layer._latlng && !layer._popup) {
                        layer.fireEvent('click');  
                        break;
                    }
                }
            }
        },
        {
            intro: `<div> Try to explore the results, by clicking on other settlements. </div>`,
            element: '#map',
        },
        {
            intro: `<div> Clicking on the export button exports all the results of the query in excel format. Go to your download folder and open the file export_settlements.xlsx to see what it looks like. </div>`,
            element: '#exportQuery',
            onbeforechange: () => {
                document.getElementById('exportQuery').click();
            }
        },
        {
            intro: `<div> This case study is now over. You can replay it at any time. If you have any question, please send the Masae team <a href="mailto:support@masae-analytics.com"> an e-mail.</a> </div>`,
        },
    ];
}


export {baseTutoSteps, caseStudySteps};
<template>
    <div class="wrapper shadow-lg bg-white">
        <div id="panel" class="shadow">
            <div class="panel-content mt-3">
                <div v-if="infoLegends.length != 0" class="mb-5">
                    <span class="text-center fs-5 fw-bolder"> Information layers</span>
                    <Legend v-for="legend in infoLegends" :key="legend.title" v-bind="legend"> </Legend>
                </div>
                <div v-if="businessLegends.length != 0" class="">
                    <span class="text-center fs-5 fw-bolder"> Business layers</span>
                    <Legend v-for="legend in businessLegends" :key="legend.title" v-bind="legend"> </Legend>
                </div>
            </div>
        </div>
        <div id="map-wrapper">
            <div class="content" id="map"></div>
            <div class="panel-controler px-1 py-5 rounded-end" @click="managePanel">
                Legend
            </div>
            <div class="bottom-left">
                <div class="content d-flex flex-column align-items-start">
                    <div class="circle-icon rounded-pill" id="homeicon" @click="goHome">
                        <img class="icone" :src="require('@/assets/img/icons/home.svg')" alt="icone">
                        <span class="tool-name"> Home &nbsp; &nbsp; </span>
                    </div>
                    <div class="circle-icon rounded-pill" id="tutorialicon" @click="startTuto">
                        <img class="icone" :src="require('@/assets/img/icons/info.svg')" alt="icone">
                        <span class="tool-name"> Tutorial &nbsp; &nbsp; </span>
                    </div>
                </div>
            </div>
            <div class="top-right layer-selector" v-if="isInit">
                <LayerSelector class="layer-info position-absolute" ref="layer_selector"
                    :section="groupedOverlays.info" :map="map" v-model="whichLayerSelector"
                    :layerGroups="layerGroups.info"
                    @layer-change="updateLegend">
                    <button class="btn mx-2 mt-2" style="font-size:10px;" type="button" data-bs-toggle="modal" data-bs-target="#contactModal"> Display mini-grid locations </button>
                </LayerSelector>
                <LayerSelector class="layer-business position-absolute" ref="layer_selector"
                    :section="groupedOverlays.business" :map="map" v-model="whichLayerSelector"
                    :layerGroups="layerGroups.business" :fakeData="true"
                    @layer-change="updateLegend">
                </LayerSelector>
            </div>
            <Tools class="top-right" ref="tools" :countryCode="countryCode" v-model:linkedData="linkedData" :legendWidth="legendWidth" :layerDisplayed="layerDisplayed" v-on:start-case-study="startCaseStudy()" />
            <!-- Contact popup -->
            <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true" data-bs-keyboard="false">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="contactModalLabel">Please contact Masae</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="fs-5 mx-4 my-3 text-start">
                            To access this feature, please contact the Masae Analytics team at:
                        </p>
                        <p class="fs-5 mx-4 text-start">
                            <a href="mailto: contact@masae-analytics.com">contact@masae-analytics.com</a>
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tools from '@/components/Tools.vue';
import Legend from '@/components/Legend.vue';
import LayerSelector from '@/components/LayerSelector.vue';
import { allMarkerStyle } from '@/util/markers.js';
import { defIconClusterStyle, fillPopup } from '@/util/utils.js';

import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-basemaps';
import 'leaflet.fullscreen';
import 'leaflet-draw';
import 'leaflet-hash';
import 'leaflet.measurecontrol';
import { baseLayers } from './baseLayers';
import { disablePropagation } from '@/util/controlWrapper.js';
import { Tutorial } from '@/util/Tutorial.js';
import { baseTutoSteps, caseStudySteps } from '@/util/tutorials.js';
import { Layer, LayerCollection } from '@/util/layer.js';

delete L.Icon.Default.prototype._getIconUrl;

// make webpack import the marker
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
const emptyTile = 'https://energy-demo.masae-analytics.com/raster/256x256_transparent.png';
const codeToCountries = {
    "ng": "Nigeria",
};

const aboveLayers = ['grid', 'region', 'district'];
const tileLayerOptions = {
    maxNativeZoom: 13,
    maxZoom: 19,
    tms: true,
    errorTileUrl: emptyTile
};
const emptyGeoJson = {
    "type": "FeatureCollection",
    "features": []
};

const panelWidth = '250px';
const panelPadding = '0px';

export const allPopupContent = {
    client: {
        nb_products: {name: "Number of products", format: 'string'},
        activation_ratio: {name: "Mean activation ratio", format: 'percent'},
        unlock_price: {name: "Unlock price", format: 'price'},
        agent_id: {name: "Recorder id", format: 'string'},
    },
    agent: {
        agent_id: {name: "Recorder id", format: 'string'},
        nb_clients: {name: "Nb of clients", format: 'number'},
        nb_products: {name: "Nb products sold", format: 'number'},
        mean_ar: {name: "Average AR", format: 'number'},
        revenue: {name: "Revenue", format: 'price'},
    }
}

export default {
    name: 'Map',
    props: ['countryCode'],
    components: {
        Tools, Legend, LayerSelector
    },
    data: function () {
        return {
            infoLegends: [],
            businessLegends: [],
            isInit: false,
            whichLayerSelector: '',
            legendWidth: '0px',
            linkedData: null,
            layerState: {"2020_HRSL": true},
        }
    },

    mounted() {
        const layer = this.$axios.get('/layer', {}).then((response) => {
            this.rawLayers = {
                'client': import(`../assets/layers/${this.countryCode}_clients.json`),
                'agent': import(`../assets/layers/${this.countryCode}_agents.json`),
                'fiber': import(`../assets/layers/${this.countryCode}_fiber.json`),
                'grid': import(`../assets/layers/${this.countryCode}_grid.json`),
                'road': import(`../assets/layers/${this.countryCode}_roads.json`),
                'region': Promise.resolve(response.data),
                'district': import(`../assets/layers/${this.countryCode}_adm2.json`),
                'nb_customer_a': Promise.resolve(response.data),
                'nb_customer_b': Promise.resolve(response.data),
                'pene_a': Promise.resolve(response.data),
                'pene_b': Promise.resolve(response.data),
                'repayment_a': Promise.resolve(response.data),
                'pop': Promise.resolve(response.data),
                'none': Promise.resolve(emptyGeoJson),
            };
        });
        this.business = ['nb_customer_a', 'nb_customer_b', 'pene_a', 'pene_b', 'repayment_a', 'pop', 'none', 'client', 'agent'];

        this.layerGroups = {
            info: {
                admin: {
                    title: "Administration",
                    exclusive: false,
                    layers: ['region', 'district'],
                },
                sociodem: {
                    title: "Socio-demographic indicators",
                    exclusive: false,
                    layers: ['2020_HRSL'],
                },
                infrastrcture: {
                    title: "Infrastructures",
                    exclusive: false,
                    layers: ['2G', '3G', '4G', 'fiber', 'grid', 'road'],
                },
            },
            business: {
                productA: {
                    title: "Product A analysis",
                    exclusive: true,
                    layers: ['nb_customer_a', 'pene_a', 'repayment_a', 'none'],
                },
                productB: {
                    title: "Product B analysis",
                    exclusive: true,
                    layers: ['nb_customer_b', 'pene_b', 'none'],
                },
                business: {
                    title: "Agents and clients",
                    exclusive: false,
                    layers: ['client', 'agent'],
                },
            },
        }

        // get config
        const conf = import(`../../../country_config/${this.countryCode}.json`).then(config => {
            this.config = config.default;
        });
        Promise.all([layer, conf]).then(() => this.initMap());
        disablePropagation(this.$refs.tools.$el);
    },

    methods: {
        initMap() {
            this.map = L.map('map', {
                zoomControl: true,
                maxZoom: 19,
                minZoom: 5,
                fullscreenControl: true,
                fullscreenControlOptions: {
                    forceSeparateButton: true,
                },
                center: this.config.center,
                zoom: this.config.zoom,
                measureControl: true
            });
            this.infoLayers = new LayerCollection(this);
            this.businessLayers = new LayerCollection(this);
            this.abovePane = this.map.createPane('above');
            new L.Hash(this.map);
            this.initControls();
            this.initLayers();
            this.$refs.tools.mount();
        },

        initLayers() {
            this.popTile = L.tileLayer(this.popTileURL, {
                maxNativeZoom: 13,
                maxZoom: 19,
                tms: true,
                errorTileUrl: emptyTile,
                attribution: 'HRSL by Facebook Connectivity Lab &amp; CIESIN, <a href="//creativecommons.org/licenses/by/4.0/">CC-BY',
                pane: 'above'
            });
            // first is added to map
            this.map.addControl(L.control.basemaps({
                basemaps: baseLayers,
            }));
            this.map.removeLayer(baseLayers[0]);
            this.map.addLayer(baseLayers[0]);

            const tileLayers = {'2020_HRSL': {opacity: 1, display: true}, '2G': {opacity: 0.4, display: false}, '3G': {opacity: 0.4, display: false}, '4G': {opacity: 0.4, display: false}};
            Object.keys(tileLayers).forEach( (layerName) => {
                const layer = L.tileLayer(`https://energy-demo.masae-analytics.com/raster/${this.countryCode.toUpperCase()}_${layerName}/{z}/{x}/{y}.png`, {...tileLayerOptions, opacity: tileLayers[layerName]["opacity"]});
                this.infoLayers.addLayer(new Layer(layerName, layer), tileLayers[layerName]["display"]);
                this.groupedOverlays.info.layers[layerName] = this.infoLayers.getLayer(layerName).leafletLayer;
            });
        },

        initControls() {
            const layerKeys = Object.keys(this.rawLayers);
            this.groupedOverlays = {
                info: {
                    type: 'info',
                    exclusive: false,
                    layers: {}
                },
                business: {
                    type: 'business',
                    exclusive: false,
                    layers: {},
                }
            }
            Promise.all(Object.values(this.rawLayers)).then((values) => {
                values.forEach((val, index) => {
                    const layerKey = layerKeys[index];
                    const params = {interactive: false};
                    if (aboveLayers.includes(layerKey)) params.pane = 'above';

                    const isCustomized = Object.keys(allMarkerStyle).includes(layerKey);
                    let iconCreateFunction;
                    let polygonOptions;
                    if (isCustomized) {
                        const mIcon = allMarkerStyle[layerKey]['markerIcon']
                        const mClass = allMarkerStyle[layerKey]['markerClass']
                        const mColor = allMarkerStyle[layerKey]['polygonColor']
                        params.pointToLayer = (_, latlng) => L.marker(latlng, {icon: mIcon});
                        iconCreateFunction = defIconClusterStyle(mClass);
                        polygonOptions = {color: mColor}
                    } else {params.pointToLayer = (_, latlng) => L.marker(latlng);}
                    let markers = L.markerClusterGroup(
                        isCustomized ? {
                        'iconCreateFunction': iconCreateFunction,
                        'polygonOptions': polygonOptions
                        } : {}
                    );
                    markers.addLayer(L.geoJSON(val, params));
                    const layer = new Layer(layerKey, markers);
                    // const layer = new Layer(layerKey, L.geoJSON(val, params));
                    if (this.business.includes(layerKey)) {
                        this.groupedOverlays.business.layers[layerKey] = layer.leafletLayer
                        this.businessLayers.addLayer(layer, false);
                    }
                    else {
                        this.groupedOverlays.info.layers[layerKey] = layer.leafletLayer;
                        this.infoLayers.addLayer(layer, false);
                    }
                    if (Object.keys(allPopupContent).includes(layerKey)) {
                        layer.leafletLayer.eachLayer(function (layer) {
                            layer.bindPopup((l) => fillPopup(l, layerKey), {className: 'basic-popup', closeOnClick: false, offset: L.point(-1, -2)});
                        });
                    }
                    if (["client", "agent"].includes(layerKey)) {
                        layer.leafletLayer.eachLayer((layer) => {
                            layer.on({
                                popupopen: (e) => this.linkedData = e.sourceTarget.feature,
                            });
                        })
                    }
                });
                // this.infoLayers.getLayer('grid').addTo(this.map);

                this.isInit = true;
                this.infoLegends = this.infoLayers.getLegends();
                this.businessLegends = this.businessLayers.getLegends();

                this.$nextTick(() => {
                    this.tourTuto = new Tutorial('tuto', baseTutoSteps());
                    this.startTuto(false);
                });
                const filterForm = this.$refs.tools.$refs.filter;
                this.caseStudyTuto = new Tutorial(null, caseStudySteps(this.config.case_study, this.map, filterForm), { skipLabel: 'Done' });

            });

        },
        goHome() {
            window.location.href = '/';
        },
        startTuto(force = true) {
            this.tourTuto.start(force);
        },
        startCaseStudy() {
            this.caseStudyTuto.start();
        },
        resetLayers() {
            Object.values(this.infoLayers).forEach(l => this.map.removeLayer(l));
            Object.values(this.businessLayers).forEach(l => this.map.removeLayer(l));
        },
        updateLegend(group, layerName, type) {
            this.layerState[layerName] = !this.layerState[layerName];
            const layer = this.business.includes(layerName) ? this.businessLayers : this.infoLayers
            if (type == 'remove') layer.removeLayer(layerName);
            else layer.displayLayer(layerName);

            this.updateLayerAndLegend(layerName);
        },
        updateLayerAndLegend(layerName, selectedProp) {
            const layer = this.business.includes(layerName) ? this.businessLayers : this.infoLayers
            layer.getLayer(layerName).setStyle(selectedProp);
            this.infoLegends = this.infoLayers.getLegends();
            this.businessLegends = this.businessLayers.getLegends();
            this.layerFilters = layer.getFilters();
        },
        managePanel() {
            const panel = document.getElementById("panel");
            if (panel.style.width != panelWidth) {
                panel.style.width = panelWidth;
                panel.style.padding = panelPadding;
            }
            else {
                panel.style.width = '0px';
                panel.style.padding = '0px';
            }
            this.legendWidth = `calc( ${panel.style.width} + ${panel.style.padding})`;
        },
    },
    computed: {
        country() {
            return codeToCountries[this.countryCode];
        },
        layerDisplayed() {
            return Object.keys(this.layerState).filter(layer => this.layerState[layer]);
        },
    },
    // depending on browser, blur sometimes do not occur
    beforeRouteLeave(to, from, next) {
        this.$el.blur();
        next();
    }
}
</script>
<style lang="scss" scoped>
@import "@/style/scss/_variables.scss";

.wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;

    #panel {
        padding: 0px;
        width: 0px;
        height: 100%;
        overflow-y: scroll;
        transition: width 1s;
        background-color: white;
    }

    #map-wrapper {
        position: relative;
        flex: 1 0 auto;
    }

    #map {
        width: 100%;
        height: 100%;
    }
}

.panel-content {
    width: 250px;
}

.panel-controler {
    z-index: 1001;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: max-content;
    writing-mode: vertical-lr;
    background-color: white;
    cursor: pointer;
}

.bottom-left {
    position: absolute;
    bottom: 115px;
    left: 10px;
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    .content {
        position: absolute;
        width: auto;
        height: auto;
    }
}
.top-right {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    width: $tool-diameter;
    .layer-business {
        top: calc(#{$tool-diameter} + 2 * #{$tool-margin-y});
    }
}

.logo {
    width: 10rem;
    padding: 0.5rem;
    left: calc(30px + 3rem);
    top: 10px;
    font-weight: bold;
    font-size: 1.2rem;
    color: $customerColor;
    background-color: rgba(255, 255, 255, 0.685);
    border: 1px solid $customerColor;
    border-radius: 1rem;

    p {
        margin: 0px;
    }

    img {
        height: auto;
        width: 100%;
        margin: auto;
    }
}
</style>
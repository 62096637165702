import * as d3 from 'd3-format';
import L from 'leaflet';
import {allPopupContent} from "@/views/Map.vue";

function fillPopup(l, layer_name) {
    const content = [];
    const popupContent = allPopupContent[layer_name];
    for (let key of Object.keys(popupContent)) {
        const name = popupContent[key]['name'];
        const format = popupContent[key]['format'];
        let value = l.feature.properties[key];
        if (!["", null, undefined].includes(value)) {
            if (format == 'number') value = d3.format(",.0f")(value)
            else if (format == 'precise_number') value = d3.format(",.2f")(value)
            else if (format == 'percent') value = d3.format(".1%")(value)
            else if (format == 'price') value = d3.format("$,.0f")(value)
            else if (format == 'boolean') value ? value = 'Yes' : value = 'No'
            else if (format == 'latlong') {
                const longlat = value.match( /-?\d+\.\d+/g );
                value = `<a href="https://www.google.com/maps/search/?api=1&query=${longlat[1]}%2C${longlat[0]}" target="_blank"><br>lat: ${longlat[1]} <br>long: ${longlat[0]}</a>`;
            }
            if (format != 'title') content.push(`<b>${name}:</b> ${value}`);
            else content.push(`<div style="font-weight: 500;font-size: 1.2rem;text-align: center;">${value}</div>`);
        }
    }
    return content.join('<br>');
}

function defIconClusterStyle(markerClass) {
    return function(cluster) {
        const childCount = cluster.getChildCount();

        return new L.DivIcon({ 
            html: `<div><span class="icon"></span> <span><b> ${childCount}</b></span> </div>`,
            className: markerClass,
            iconSize: L.Point(75, 30),
            iconAnchor:  [40, 40],
        });
    }
}

export { fillPopup, defIconClusterStyle }

const trans = {
    info: "Information layers",
    "2020_HRSL": "Population settlements",
    region: "Regions boundaries",
    district: "District boundaries",
    grid: "Electricity grid",
    road: "Roads",
    fiber: "Optical fiber",
    "2G": "Mobile coverage - 2G",
    "3G": "Mobile coverage - 3G",
    "4G": "Mobile coverage - 4G",
    business: "Business layers",
    pop: "Regional population",
    repayment_a: "Repayment efficiency for product A",
    pene_a: "A penetration rate",
    pene_b: "B penetration rate",
    nb_customer_a: "Number of customer for A",
    nb_customer_b: "Number of customer for B",
    none: "None",
    client: "Clients",
    agent: "Agents",
};

export {trans};
import { createI18n } from 'vue-i18n';

function loadMessages() {
    const locales = require.context('./locales', true, /[a-z]{2}\.js/);
    const messages = {};
    locales.keys().forEach(langFile => {
        const matched = langFile.match(/([a-z]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(langFile).trans;
        }
    });
    return messages;
}

export default createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages: loadMessages(),
    warnHtmlInMessage: 'off',
});

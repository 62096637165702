import { createWebHistory, createRouter } from "vue-router";
import Map from '@/views/Map.vue'
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
    { path: '/', redirect: { name: 'home' }},
    {
        path: '/ng',
        name: 'ng',
        component: Map,
        props: { countryCode: 'ng'},
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
    }
];

routes.push({
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router

<template>
    <div class="content d-flex flex-row-reverse">
        <div class="circle-icon rounded-pill" :id="this.section.type + 'icon'" @click="toggleSelector">
            <img class="icone" :src="this.typeInfo.img" alt="icone">
            <span class="tool-name"> {{ this.typeInfo.title }} &nbsp; &nbsp; </span>
        </div>
        <div class="wrapper hide" :id="section.type + 'menu'">
            <div class="pb-2">
                <div class="d-flex justify-content-center">
                    <span class="d-block fs-5 fw-bold text-center mb-3"> {{ $t(section.type) }} </span>
                    <icon v-if="fakeData" class="ms-2" :name="'icon-warning'" :height="'1.8em'" :width="'1.8em'" :tooltipContent="'Fake data for demo purposes'"/>
                </div>
                <div v-for="(group, groupName) in layerGroups" :key="groupName">
                    <span class="d-block mt-3 mb-1 fw-bold text-start"> {{ group.title }} </span>
                    <div class="layer-select" v-for="(layer, layerName) in section.layers" :key="layerName">
                        <div v-if="group.layers.includes(layerName)" class="box-select d-flex align-items-center">
                            <div v-if="group.type == 'choroplethe'">
                                <div class="d-flex" v-for="choice in group.choices" :key="choice">
                                    <input class="me-2" :type="group.exclusive ? 'radio' : 'checkbox'" :value="choice"
                                        :name="section.type" :id="section.type + choice"
                                        @change="toggleLayer($event, section.type, layerName, layer, group.exclusive)">
                                    <label :for="section.type + choice" v-html="$t(choice)"> </label>
                                </div>
                            </div>
                            <div v-else class="d-flex">
                                <input class="me-2" :type="group.exclusive ? 'radio' : 'checkbox'" :value="layerName"
                                    :name="section.type" :id="section.type + layerName"
                                    @change="toggleLayer($event, section.type, layerName, layer, group.exclusive)">
                                <label :for="section.type + layerName" v-html="$t(layerName)"> </label>
                            </div>
                        </div>
                    </div>
                </div>
                <slot> </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'layer-selector',
    emits: ['update:modelValue', 'layer-change'],
    props: {
        section: Object,
        map: Object,
        modelValue: [String, Array],
        layerGroups: Object,
        fakeData: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            exclusiveValue: null,
        }
    },
    created() {
        this.separatorName = 'separator';
    },
    mounted() {
        // handle already present layers and callbacks when added
        Object.entries(this.section.layers).forEach(([layerName, layer]) => {
            if (layerName == this.separatorName) return;
            if (this.map.hasLayer(layer)) this.toggleTick(this.section.type, layerName, true);
            layer.on('add', () => this.toggleTick(this.section.type, layerName, true));
            layer.on('remove', () => this.toggleTick(this.section.type, layerName, false));
        });
    },
    computed: {
        typeInfo() {
            if (this.section.type == 'info') return { title: 'Information layers', img: require('@/assets/img/icons/people.svg') }
            else if (this.section.type == 'business') return { title: 'Business layers', img: require('@/assets/img/icons/suitcase.svg') }
            else return ''
        },
        whichLayerSelector: {
            get() {
                return this.modelValue
            },
            set(whichLayerSelector) {
                this.$emit('update:modelValue', whichLayerSelector)
            }
        },
    },
    watch: {
        whichLayerSelector(newValue) {
            const el1 = document.getElementById(this.section.type + 'menu');
            const el2 = document.getElementById(this.section.type + 'icon');
            if (newValue == this.section.type) {
                el1.classList.remove("hide");
                el2.classList.add("active");
            }
            else {
                el1.classList.add("hide");
                el2.classList.remove("active");
            }
        }
    },
    methods: {
        // called on input click 
        toggleLayer(e, groupName, layerName, layer, exclusive) {
            const checked = e.target.checked;
            const group = this.section;
            this.$emit('layer-change', groupName, layerName, checked ? 'add' : 'remove');
            if (exclusive) {
                Object.entries(group.layers).forEach(([curLayerName, curLayer]) => {
                    if (curLayerName == this.separatorName) return;
                    if (layer == curLayer && checked) {
                        this.map.addLayer(curLayer);
                        return;
                    }
                    if (this.exclusiveValue == curLayerName) {
                        this.$emit('layer-change', groupName, curLayerName, 'remove');
                        this.map.removeLayer(curLayer);
                    }
                });
                this.exclusiveValue = layerName;
                return;
            }
            if (checked && !this.map.hasLayer(layer)) this.map.addLayer(layer);
            else if (!checked && this.map.hasLayer(layer)) this.map.removeLayer(layer);
        },
        // called by adding/removing a layer programaticaly
        toggleTick(groupName, layerName, value) {
            const el = document.getElementById(groupName + layerName);
            el.checked = value;
        },
        toggleSelector() {
            if (this.whichLayerSelector != this.section.type) this.whichLayerSelector = this.section.type;
            else this.whichLayerSelector = '';
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../style/scss/global';
.content {
    position: absolute;
    width: auto;
    height: auto;
}

.hide {
    display: none !important;
}

.wrapper {
    margin: 0.3rem 0.5rem;
    padding: 1em 1.5em;
    // margin: 0.3rem 0.5rem;
    background-color: white;
    border-radius: 5px;
    min-width: 220px;
}

.layer-group {
    margin-top: 0.5rem;
}

.layer-group>span {
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
}

.seperator {
    width: 85%;
    margin: 0.9rem auto;
    border-bottom: 2px solid black;
    display: block;
    text-align: center;
    line-height: 0.05rem;

    span {
        background: white;
        padding: 0 0.5rem;
        font-weight: bold;
    }
}

.layer-select {
    text-align: left;
}
</style>
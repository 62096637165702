function bboxToWKT(nw, ne, sw, se) {
    return "POLYGON((" + nw.lng + " " + nw.lat +","+ ne.lng + " " + ne.lat +","
        + se.lng + " " + se.lat +","+ sw.lng + " " + sw.lat + ","+ nw.lng + " " + nw.lat + "))";
}

function pointToWKT(lng, lat) {
    return "POINT(" + lng + " " + lat + ")";
}

function WKTToLatLngBounds(box) {
    const points = box.slice(4,-1).split(',');
    const ptA = points[0].split(' ').reverse().map(function (item) {return parseFloat(item);});
    const ptB = points[1].split(' ').reverse().map(function (item) {return parseFloat(item);});
    return [ptA, ptB];
}

export {bboxToWKT, pointToWKT, WKTToLatLngBounds};
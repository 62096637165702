import CustomMarker from './CustomMarker.js';

const suitcaseSVG = require('@/assets/img/icons/suitcase.svg')
const personSVG = require('@/assets/img/icons/person.svg')

const agentColor = '#277DA1';
const communityColor = '#7678ed';

const agentMarker = new CustomMarker({
    iconPath: suitcaseSVG,
    whiteIcon: false,
    color: agentColor,
});

const clientMarker = new CustomMarker({
    iconPath: personSVG,
    whiteIcon: false,
    color: communityColor,
});


const allMarkerStyle = {
    agent: {
        markerIcon: agentMarker, 
        polygonColor: agentColor, 
        markerClass: "agent",
    },
    client: {
        markerIcon: clientMarker, 
        polygonColor: communityColor, 
        markerClass: "client",
    },
}

export { allMarkerStyle, agentColor, communityColor };
